import React, { type FC } from 'react';
import style from './Step2SectionModalAutoTrader.module.css';
import { Success2FAIcon } from '../../../icons/Success2FAIcon';
import { t } from 'i18next';
import { useAppSelector } from '../../../../hooks/redux';
import { CrossInputIcon } from '../../../icons/CrossInputIcon';

type RiskLevel = 'low' | 'medium' | 'high' | 'disabled';

const riskToDays: { [key in RiskLevel]: string } = {
  disabled: '0',
  low: '1-3',
  medium: '3-4',
  high: '5+'
};

export const Step2SectionModalAutoTrader: FC = () => {
  const riskLevel = useAppSelector((state) => state.autotrader.riskLevel as RiskLevel);
  const isDisabled = riskLevel === 'disabled';

  return (
    <section className={style.sectionWrapper}>
      <div className={style.mainStep2}>
        {isDisabled
          ? (
            <CrossInputIcon width="80" />
            )
          : (
            <Success2FAIcon width="100" />
            )}
        <div className={style.activatedText1}>
          <p>{t(isDisabled ? 'trading_page.labels.autoTraderDeactivated' : 'trading_page.labels.autoTraderActivated')}</p>
        </div>
        <div className={style.activatedText2}>
          <p>{t(isDisabled ? 'trading_page.labels.tradingDisabled' : 'trading_page.labels.tradingEnabled')}</p>
        </div>
      </div>
      <div className={style.secondStep2}>
        <div className={style.riskLevel}>
          <div className={style.riskLevelText}>{t('trading_page.labels.risk')}</div>
          <div className={style.riskLevelValue}>{riskLevel}</div>
        </div>
        <div className={style.perDay}>
          <div className={style.perDayText}>{t('trading_page.labels.tradesDay')}</div>
          <div className={style.perDayValue}>{riskToDays[riskLevel]}</div>
        </div>
      </div>
    </section>
  );
};
