import React, { type FC } from 'react';
import { t } from 'i18next';

import styles from './BankDepositInvoice.module.css';

interface BankDepositInvoiceProps {
  userName: string
  formattedDate: string
  currencySymbol: string
  amount: string
  accountNumber: string
  bankName: string
  swift: string
  iban: string
  bankAddress: string
  beneficiary: string
  beneficiaryAddress: string
  correspondentAccount: string
  inn: string
  kpp: string
  reference: string
  customId: string
}

export const BankDepositInvoice: FC<BankDepositInvoiceProps> = ({
  userName,
  formattedDate,
  currencySymbol,
  amount,
  accountNumber,
  beneficiary,
  beneficiaryAddress,
  correspondentAccount,
  inn,
  kpp,
  reference,
  bankName,
  swift,
  iban,
  bankAddress,
  customId
}): JSX.Element => {
  return (
    <div className={styles.invoiceWrapper} id="invoice">
      <h1 className={styles.invoiceHeader}>{t('labels.bank_transfer')}</h1>
      <div className={styles.invoiceInfoWrapper}>
        <div className={styles.invoiceInfoHeading}>
          <div className={styles.invoiceInfoElement}>
              <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.billed_to')}</span>
              <span className={`${styles['black-bold']} ${styles['font-14']}`}>{userName}</span>
              </div>
              <div className={styles.invoiceInfoElement}>
                <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.invoice_date')}</span>
                <span className={`${styles['black-bold']} ${styles['font-14']}`}>{formattedDate}</span>
              </div>
              <div className={styles.invoiceInfoElement}>
                <span className={`${styles['gray-bold']} ${styles['font-14']} ${styles['text-right']}`}>{t('labels.invoice_of')} ({currencySymbol})</span>
                <span className={`${styles['blue-color']} ${styles['font-20']}`}>{currencySymbol} {amount}</span>
              </div>
            </div>
            <div className={styles.paymentDetailsWrapper}>
              <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('labels.payment_details')}</span>
              <div className={styles.paymentDetailsInfo}>
                {
                  (accountNumber.length > 0)
                    ? (<div className={styles.paymentDetailsInfoElement}>
                        <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('bankData.account_number')}: </span>
                        <span className={`${styles['black-bold']} ${styles['font-14']}`}>{accountNumber}</span>
                      </div>)
                    : null
                }
                {
                  (iban.length > 0)
                    ? (<div className={styles.paymentDetailsInfoElement}>
                        <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('bankData.iban')}: </span>
                        <span className={`${styles['black-bold']} ${styles['font-14']}`}>{iban}</span>
                      </div>)
                    : null
                }
                {
                  (bankName.length > 0)
                    ? (<div className={styles.paymentDetailsInfoElement}>
                      <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('bankData.bank_name')}: </span>
                      <span className={`${styles['black-bold']} ${styles['font-14']}`}>{bankName}</span>
                      </div>)
                    : null
                }
                {
                  (beneficiary.length > 0)
                    ? (<div className={styles.paymentDetailsInfoElement}>
                      <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('bankData.beneficiary')}: </span>
                      <span className={`${styles['black-bold']} ${styles['font-14']}`}>{beneficiary}</span>
                      </div>)
                    : null
                }
                {
                  (beneficiaryAddress.length > 0)
                    ? (<div className={styles.paymentDetailsInfoElement}>
                        <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('bankData.beneficiary_address')}: </span>
                        <span className={`${styles['black-bold']} ${styles['font-14']}`}>{beneficiaryAddress}</span>
                      </div>)
                    : null
                }
                {
                  (correspondentAccount.length > 0)
                    ? (<div className={styles.paymentDetailsInfoElement}>
                        <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('bankData.correspondent_account')}: </span>
                        <span className={`${styles['black-bold']} ${styles['font-14']}`}>{correspondentAccount}</span>
                      </div>)
                    : null
                }
                {
                  (reference.length > 0)
                    ? (<div className={styles.paymentDetailsInfoElement}>
                        <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('bankData.reference')}: </span>
                        <span className={`${styles['black-bold']} ${styles['font-14']}`}>{reference}</span>
                      </div>)
                    : null
                }
                {
                  (swift.length > 0)
                    ? (<div className={styles.paymentDetailsInfoElement}>
                        <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('bankData.swift_code')}: </span>
                        <span className={`${styles['black-bold']} ${styles['font-14']}`}>{swift}</span>
                      </div>)
                    : null
                }
                {
                  (inn.length > 0)
                    ? (<div className={styles.paymentDetailsInfoElement}>
                      <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('bankData.inn')}: </span>
                      <span className={`${styles['black-bold']} ${styles['font-14']}`}>{inn}</span>
                    </div>)
                    : null
                }
                {
                  (kpp.length > 0)
                    ? (<div className={styles.paymentDetailsInfoElement}>
                        <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('bankData.kpp')}: </span>
                        <span className={`${styles['black-bold']} ${styles['font-14']}`}>{kpp}</span>
                      </div>)
                    : null
                }
                {
                  (bankAddress.length > 0)
                    ? (<div className={styles.paymentDetailsInfoElement}>
                        <span className={`${styles['gray-bold']} ${styles['font-14']}`}>{t('bankData.bank_address')}: </span>
                        <span className={`${styles['black-bold']} ${styles['font-14']}`}>{bankAddress}</span>
                      </div>)
                    : null
                }
              </div>
            </div>
            <div className={styles.descriptionDetailsWrapper}>
              <div className={styles.descriptionDetailsInfoHeading}>
                <span>{t('labels.description')}</span>
                <span>{t('labels.amount')}</span>
              </div>
              <div className={styles.desciptionDetailsAmount}>
                <span style={{ fontSize: '10px', fontWeight: 700 }}>{customId}</span>
                <span>{currencySymbol} {amount}</span>
              </div>
              <div className={styles.subtotal}>
                <div className={`${styles.mediumWidth} ${styles['border-b']}`}>
                  <span>{t('labels.subtotal')}</span>
                  <span>{currencySymbol} {amount}</span>
                </div>
              </div>
              <div className={styles.total}>
                <div className={styles.mediumWidth}>
                  <span style={{ fontSize: '14px', fontWeight: 700 }}>{t('labels.total')}</span>
                  <span style={{ fontSize: '14px', fontWeight: 700 }}>{currencySymbol} {amount}</span>
                </div>
          </div>
        </div>
      </div>
    </div>
  )
}
