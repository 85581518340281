import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

export interface IBankData {
  _id: string
  name: string
  iban: string
  accountNumber: string
  bankAddress: string
  swiftCode: string
  currencyName: string
  beneficiary: string
  beneficiaryAddress: string
  correspondentAccount: string
  inn: string
  kpp: string
  alias: string
  reference: string
}

interface ICurrency {
  name: string
  symbol: string
  _id: string
}

interface IOther {
  _id: string
  brandIds: string[]
  status: boolean
  imageUrl: string
  url: string
  label: string
}

export interface IDefaultAddress {
  _id: string
  address: string
  currency: ICurrency
  network?: string
}

interface IPSP {
  defaultBank: IBankData[]
  defaultAddresses: IDefaultAddress[]
  others: IOther[]
}

const initialState: IPSP = {
  defaultBank: [{
    _id: '',
    name: '',
    iban: '',
    alias: '',
    accountNumber: '',
    bankAddress: '',
    swiftCode: '',
    currencyName: '',
    beneficiary: '',
    beneficiaryAddress: '',
    correspondentAccount: '',
    inn: '',
    kpp: '',
    reference: ''
  }],
  defaultAddresses: [],
  others: []
}

export const getBankDataForCurrentUser = createAsyncThunk(
  'psp/bankAccountForUser/get',
  async ({ countryCode, userId }: { countryCode: string, userId: string }) => {
    const response = await apiHelper({ method: 'get', path: `/admin-bank-account/get-bank-account-for-user?countryCode=${countryCode}&userId=${userId}` });

    return response.data.bankAccountDetails;
  }
);

export const getDefaultAddresses = createAsyncThunk(
  'psp/defaultAddresses/get',
  async () => {
    const response = await apiHelper({ method: 'get', path: '/admin-address/default-adresses' });

    return response.data.addresses;
  }
);

export const getOthers = createAsyncThunk(
  'psp/others/get',
  async ({ brandId, countryCode }: { brandId: string, countryCode: string }) => {
    const response = await apiHelper({ method: 'get', path: `/other-psp/query?brandId=${brandId}&countryCode=${countryCode}` });

    return response.data.pspOthers;
  }
);

export const searchOthers = createAsyncThunk(
  'psp/others/search',
  async ({ label, brandId, countryCode }: { label: string, brandId: string, countryCode: string }, { dispatch }) => {
    const response = await apiHelper({
      method: 'get',
      path: '/other-psp/search',
      params: {
        searchString: label,
        brandId,
        countryCode
      }
    });

    dispatch(setOtherPsp(response.data.othersPsp as IOther[]));
  }
);

export const pspSlice = createSlice({
  name: 'psp',
  initialState,
  reducers: {
    setOtherPsp (state, action: PayloadAction<IOther[]>) {
      state.others = action.payload;
    },
    addOtherPsp (state, action: PayloadAction<IOther>) {
      state.others.push(action.payload);
    },
    editOtherPsp (state, action: PayloadAction<IOther>) {
      const index = state.others.findIndex((psp) => psp._id === action.payload._id);
      if (index !== -1) {
        state.others[index] = action.payload;
      }
    },
    removeOtherPsp (state, action: PayloadAction<IOther>) {
      const newOthers = state.others.filter(item => item._id !== action.payload._id);

      state.others = newOthers
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBankDataForCurrentUser.fulfilled, (state, action: PayloadAction<IBankData[]>) => {
      state.defaultBank = action.payload;
    });
    builder.addCase(getDefaultAddresses.fulfilled, (state, action: PayloadAction<IDefaultAddress[]>) => {
      state.defaultAddresses = action.payload;
    });
    builder.addCase(getOthers.fulfilled, (state, action: PayloadAction<IOther[]>) => {
      state.others = action.payload;
    });
  }
});

export const { setOtherPsp, addOtherPsp, editOtherPsp, removeOtherPsp } = pspSlice.actions;
export default pspSlice.reducer;
