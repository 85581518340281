import React, { type FC, useState, type ChangeEvent } from 'react';
import { ButtonComponent, CheckboxComponent } from '../../../components';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import { updateAutotrader } from '../../../redux/reducers/autotrader';
import style from './FooterModalAutoTrader.module.css';
import { t } from 'i18next';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { setTradingMode } from '../../../redux/reducers/tradingMode';

interface InterfaceFooterModalAutoTrader {
  step: number
  setStep: (value: number) => void
  setOpen: (el: boolean) => void
}

export const FooterModalAutoTrader: FC<InterfaceFooterModalAutoTrader> = ({ step, setStep, setOpen }) => {
  const dispatch = useAppDispatch();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [confirmAgreement, setConfirmAgreement] = useState<boolean>(false);
  const autotrader = useAppSelector((state) => state.autotrader);
  const { _id: userId } = useAppSelector((state) => state.user);
  const [widthScreen] = useWindowSize();
  const isMobile = (widthScreen <= 905);

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target: { checked } } = event;
    setConfirmAgreement(checked);
  };

  const handleClick = async (): Promise<void> => {
    setIsButtonLoading(true);

    if (userId != null) {
      await dispatch(updateAutotrader({ userId, riskLevel: autotrader.riskLevel, isEnabled: autotrader.isEnabled }));
    }
    setIsButtonLoading(false);
    setStep(2)
  };

  const handleBtnClick = (): void => {
    setOpen(false)
  }

  const handleViewTrading = (): void => {
    if (isMobile) {
      setOpen(false);
      dispatch(setTradingMode(false));
    } else setOpen(false);
  };

  return (
    <footer className={style.footerWrapper}>
      {step === 1
        ? (
        <>
          <div className={style.footerAgreement}>
            <CheckboxComponent onChange={handleCheckbox} checked={confirmAgreement} />
            <p className={style.footerAgreementText}>
            {t('trading_page.labels.autoTraderConditions')}
              <a href='lplplp' className={style.footerAgreementLink}>
                      this link name...
              </a>
            </p>
          </div>
        <div className={style.footerBtnContainer}>
          <ButtonComponent
            disabled={!confirmAgreement}
            loading={isButtonLoading}
            onClick={() => { handleClick().catch(console.error); }}
          >
          {t('labels.save')}
          </ButtonComponent>
        </div>
      </>
          )
        : (
      <>
        <div className={style.footerBtnContainerStep2}>
          <ButtonComponent
            disabled={!confirmAgreement}
            loading={isButtonLoading}
            onClick={() => { handleBtnClick() }}
            variant='outlined'
          >
          {t('accounts_page.cancel')}
          </ButtonComponent>
          <ButtonComponent
            disabled={!confirmAgreement}
            loading={isButtonLoading}
            onClick={() => { handleViewTrading() }}
          >
          {t('trading_page.labels.viewTrading')}
          </ButtonComponent>
        </div>
      </>
          )}
    </footer>
  );
};
