import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './axiosBaseQuery';

export const withdrawalTypesApi = createApi({
  reducerPath: 'withdrawalTypesApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getWithdrawalTypes: builder.query({
      query: ({ brandId, countryCode, userId }) => ({
        method: 'GET',
        path: `/withdrawal-types/get-name-status-withdrawal-types?brandId=${brandId}&countryCode=${countryCode}&userId=${userId}`
      })
    })
  })
});

export const { useGetWithdrawalTypesQuery } = withdrawalTypesApi;
