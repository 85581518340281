import React, { type FC, useState, useEffect, useMemo, type ChangeEvent, type FocusEvent } from 'react';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getWallets, addWallet } from '../../../../redux/reducers/withdrawalMethods';
import { getCurrencyRates } from '../../../../redux/reducers/currencyRates';
import { createExternalTransaction, getTransactionsHistory } from '../../../../redux/reducers/transactionsHistory';
import {
  AssetAmountInputComponent,
  ButtonComponent,
  CheckboxComponent
} from '../../../../components';
import { SavedCryptoWithdrawalOptions } from './SavedCryptoWithdrawalOptions/SavedCryptoWithdrawalOptions';
import { SuccessWithdrawalSection } from '../SuccessWithdrawalSection/SuccessWithdrawalSection';
import { AddCryptoFormComponent } from '../AddCryptoFormComponent/AddCryptoFormComponent';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { toFixed } from '../../../../helpers/util';
import { type OptionInterface } from '../../../../interfaces';
import { getNetworks } from '../../../../redux/reducers/networks';
import { getCryptoCurrencies } from '../../../../redux/reducers/currencies';
import { WarningBetweenSymbolComponent } from '../WarningBetweenSymbolComponent/WarningBetweenSymbolComponent';
import { useWithdrawalDispatchTransaction } from '../../../../hooks/useWithdrawalDispatchTransaction';

import style from './CryptoWithdrawalSection.module.css';

export interface TypeCryptoOption { coin: string, network: string, wallet: string }

interface InterfaceCryptoWithdrawSection {
  setOpen: (el: boolean) => void
  setStep: (el: number) => void
  step: number
  selectedAccount: IAccount | null
}

interface ICreateWithdrawalWalletData {
  userId: string
  currencyId: string
  userAccountId: string
  sendToAddress: string
  amount: number | string
  amountFromAccount: string
  type: 'Crypto'
}

interface IAddWalletData {
  name: string
  address: string
  currencyId: string
  userId: string
}

export const CryptoWithdrawSection: FC<InterfaceCryptoWithdrawSection> = ({ setOpen, setStep, step, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [cryptoSelect, setCryptoSelect] = useState<string>('');
  const [cryptoCurrencySelect, setCryptoCurrencySelect] = useState<OptionInterface>({
    value: '',
    label: '',
    symbol: ''
  });
  const [networkSelect, setNetworkSelect] = useState<OptionInterface>({
    value: '',
    label: ''
  });
  const [walletAdress, setWalletAdress] = useState<string>('');
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const [isAddWallet, setIsAddWallet] = useState<boolean>(false);
  const { executeTransaction } = useWithdrawalDispatchTransaction(setIsBtnLoading);

  const { wallets } = useAppSelector((state) => state.withdrawalMethods);
  const { _id: userId, firstName, lastName } = useAppSelector((state) => state.user);
  const networks = useAppSelector((state) => state.networks);
  const currencies = useAppSelector((state) => state.currencies);
  const { rates: currencyRates } = useAppSelector((state) => state.currencyRates);

  useEffect(() => {
    if (userId === undefined) return;

    dispatch(getWallets(userId));
  }, [userId]);

  useEffect(() => {
    if (cryptoCurrencySelect.value.length === 0) return;

    dispatch(getNetworks(cryptoCurrencySelect.value));
  }, [cryptoCurrencySelect])

  useEffect(() => {
    dispatch(getCryptoCurrencies());
  }, []);

  useEffect(() => {
    if (selectedAccount !== null) dispatch(getCurrencyRates(selectedAccount.cfdAccountCurrency.symbol));
  }, [selectedAccount]);

  const networkOptions = useMemo(() => {
    return networks.map((network) => ({ label: network.name, value: network._id }));
  }, [networks]);

  const validCryptoAccount = networkOptions.length > 0
    ? (networkSelect.value.length > 0 && walletAdress.length > 0 && cryptoCurrencySelect.value.length > 0)
    : (walletAdress.length > 0 && cryptoCurrencySelect.value.length > 0);

  useEffect(() => {
    if (wallets.length > 0 && wallets !== undefined) {
      const [{ _id }] = wallets;
      setCryptoSelect(_id);
    }
  }, [wallets])

  const currencyOptions = useMemo(() => {
    return currencies.map((currency) => ({ label: currency.name, value: currency._id, symbol: currency.symbol }));
  }, [currencies]);

  const selectedWallet = useMemo(() => {
    return wallets.find(({ _id }) => _id === cryptoSelect) ?? null;
  }, [cryptoSelect])

  const formSelectedCurrency = useMemo(() => {
    return currencies.find(({ _id }) => _id === cryptoCurrencySelect.value);
  }, [cryptoCurrencySelect])

  const currencySymbol = (isAddWallet || validCryptoAccount) ? formSelectedCurrency?.symbol : selectedWallet?.currency?.symbol;

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    setCryptoSelect('');
  };

  const checkBoxHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCheckboxValue(!checkboxValue);
    setIsAddWallet(!isAddWallet);
  }

  const convertCryptoAmount = (symbol: string): number => {
    if (symbol.length === 0) return 0;
    const currencyRate = Number(currencyRates?.[symbol] ?? 0);
    const calculatedAmount = toFixed(Number(amount) / currencyRate, 5);
    return calculatedAmount;
  }

  const handleWithdrawlClick = async (): Promise<void> => {
    let formWalletData: Record<string, string> = {};
    if (validCryptoAccount) {
      formWalletData = {
        address: walletAdress,
        currency: formSelectedCurrency?.symbol ?? ''
      }
    }

    const validSelectedAccount = (selectedWallet === null && !validCryptoAccount);

    if (userId === undefined || validSelectedAccount || selectedAccount === null) return;

    setIsBtnLoading(true);
    const cryptoWallet = ((isAddWallet || validCryptoAccount) && (selectedWallet == null)) ? formWalletData : selectedWallet ?? {};

    if (isAddWallet) {
      const addWalletData: IAddWalletData = {
        name: `${firstName} ${lastName}'s Wallet`,
        address: walletAdress,
        currencyId: cryptoCurrencySelect.value,
        userId
      };

      const addCryptoSuccess = await executeTransaction(addWallet, addWalletData);
      if (!addCryptoSuccess) return;
    }

    const createWithdrawalWalletData = (): ICreateWithdrawalWalletData => {
      return {
        userId,
        currencyId: cryptoCurrencySelect.value,
        userAccountId: selectedAccount._id,
        sendToAddress: cryptoWallet.address,
        amount: Number(amount),
        amountFromAccount: amount,
        type: 'Crypto'
      };
    };

    const withdrawalData = createWithdrawalWalletData();
    const transactionSuccess = await executeTransaction(createExternalTransaction, withdrawalData);

    if (!transactionSuccess) return;

    dispatch(getTransactionsHistory({ userId, accountId: selectedAccount._id }));
    setIsBtnLoading(false);

    setStep(2);
  }

  const handleCloseWithdrawal = (): void => {
    setOpen(false);
  }

  const savedCryptoHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setCryptoSelect(event.target.value);
    setNetworkSelect({
      value: '',
      label: ''
    });
    setWalletAdress('');
    setCryptoCurrencySelect({
      value: '',
      label: '',
      symbol: ''
    });
    setCheckboxValue(false);
    setIsAddWallet(!isAddWallet);
  }

  const amountComplete = (amount.length > 0 && Number(amount) > 0);
  const disableBtn = (validCryptoAccount || (selectedWallet != null)) && amountComplete;
  const walletAdressView = ((isAddWallet || validCryptoAccount)) ? walletAdress : selectedWallet?.address;
  const cryptoCurrencyView = ((isAddWallet || validCryptoAccount))
    ? `${formSelectedCurrency?.name} (${formSelectedCurrency?.symbol})`
    : `${selectedWallet?.currency?.name} (${selectedWallet?.currency?.symbol})`;
  const isShowWarning = amount.length > 0;

  return (
    <>
      { (step === 1)
        ? <>
            <div className={ style.wrapper }>
              { wallets.length > 0 && (<div className={ style.cardWrapper }>
                  <div className={ style.savedCardsWrapper }>
                    <p className={ style.cardWrapperText }>{t('labels.choose_crypto')}</p>
                    <SavedCryptoWithdrawalOptions selectedOptions={ cryptoSelect } setSelectedOptions={ setCryptoSelect } options={ wallets } onChange={ savedCryptoHandler }/>
                  </div>
              </div>) }
              <div className={ style.cardWrapper }>
                <p className={ style.cardWrapperText }>{t('withdrawal_modal.add_crypto_form_modal.title')}</p>
                <AddCryptoFormComponent
                  cryptoSelect={ cryptoCurrencySelect }
                  setCryptoSelect={ setCryptoCurrencySelect }
                  networkSelect={ networkSelect }
                  setNetworkSelect={ setNetworkSelect }
                  walletAddress={ walletAdress }
                  setWalletAddress={ setWalletAdress }
                  networkOptions={ networkOptions as OptionInterface[] }
                  currencyOptions={ currencyOptions as OptionInterface[] }
                  onFocus={ handleFocus }
                />
              </div>
              <div className={style.checkboxContainer}>
                  <CheckboxComponent disabled={ !validCryptoAccount } checked={ checkboxValue } onChange={ checkBoxHandler }/>
                  <p className={style.checkboxTitle}>{t('withdrawal_modal.save_crypto_card_for_future')}</p>
                </div>
              <div
                className={ style.cardWrapper }
                style={{ paddingBottom: isShowWarning ? '32px' : '6px' }}
              >
                <p className={ style.cardWrapperText }>2. {t('labels.amount')}</p>
                <div className={ style.inputWrapper }>
                  <label htmlFor='amount' className={ style.label }>{t('labels.amount')}</label>
                  <AssetAmountInputComponent
                    value={ amount }
                    setValue={ setAmount }
                    id='amount'
                    currency={formSelectedCurrency?.symbol ?? selectedWallet?.currency?.symbol}
                    maxBtn={ false }
                    placeholder='0.00'
                    showCurrency={false}
                  />
                </div>
              </div>
            </div>
            <footer className={ style.footer }>
              {
                isShowWarning && (
                  <WarningBetweenSymbolComponent
                    cryptoAmount={ `${amount} ${currencySymbol}`}
                    convertCryptoAmount={ convertCryptoAmount(currencySymbol ?? '') }
                    sectionName='Crypto'
                    currencySymbol={selectedAccount?.cfdAccountCurrency.symbol}
                  />
                )
              }
              <div className={ style.btnContainer }>
                <ButtonComponent onClick={() => { handleWithdrawlClick(); } } disabled={ !disableBtn } loading={isBtnLoading}>
                  <span className={ style.btnText }>
                    {t('withdrawal_modal.add_bank_form_modal.footer.action_button_text')}
                  </span>
                </ButtonComponent>
              </div>
            </footer>
          </>
        : <SuccessWithdrawalSection
            currency={''}
            title={t('withdrawal_modal.success_title')}
            amount={`${amount} ${currencySymbol}`}
            withdrawalTo={ walletAdressView }
            onClick={ handleCloseWithdrawal }
            cryptoCurrency={ cryptoCurrencyView }
            walletAdress={ walletAdressView }
            sectionName='Crypto'
          />
      }
    </>
  )
}
