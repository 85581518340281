import React, { type FC, useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useAppSelector } from '../../../../../../hooks/redux';
import { Link } from 'react-router-dom';
import { Menu } from '@mui/material';
import styles from './PreferredAssets.module.css';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { CheckboxComponent } from '../../../../../../components';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';

const CustomMenu = styled(Menu)(() => ({
  ' .MuiList-root': {
    backgroundColor: 'var(--CFD-theme-Surface-3)',
    width: '236px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 15px'
  }
}))

interface IAsset {
  name: string
  symbol: string
  _id: string
  selected: boolean
}

interface IPreferredAssets {
  isNewsShown: boolean
  isMarketShown: boolean
  setIsMarketShown: Dispatch<SetStateAction<boolean>>
  setIsNewsShown: Dispatch<SetStateAction<boolean>>
}

export const PreferredAssets: FC<IPreferredAssets> = ({ isNewsShown, isMarketShown, setIsMarketShown, setIsNewsShown }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [historyAssets, setHistoryAssets] = useState<IAsset[]>([]);
  const { assetInfo, marketData } = useAppSelector((state) => state.cfd);

  const getHistoryAssets = (): IAsset[] => {
    const HISTORY_ASSETS_JSON = localStorage.getItem('history-assets') ?? null;

    if (HISTORY_ASSETS_JSON === null) {
      localStorage.setItem('history-assets', JSON.stringify([]));
      return [];
    }

    return JSON.parse(HISTORY_ASSETS_JSON);
  }

  const handleRemoveAsset = (e: React.MouseEvent<HTMLElement>, id: string): void => {
    e.preventDefault();
    const updatedHistory = historyAssets.filter((asset) => asset._id !== id);
    localStorage.setItem('history-assets', JSON.stringify(updatedHistory));
    setHistoryAssets(updatedHistory);
  }

  useEffect(() => {
    if (assetInfo._id.length === 0) return;

    const history = getHistoryAssets();
    const assetInHistory = history.find((asset) => asset._id === assetInfo._id) ?? null;

    if (assetInHistory === null) {
      history.push({
        selected: true,
        symbol: assetInfo.symbol,
        name: assetInfo.label,
        _id: assetInfo._id
      })
    }
    const updatedHistory = history.map((asset) => {
      asset.selected = asset._id === assetInfo._id;

      return asset;
    });

    updatedHistory.sort((a: IAsset, b: IAsset) => {
      return (b.selected === a.selected) ? 0 : a.selected ? -1 : 1;
    });

    localStorage.setItem('history-assets', JSON.stringify(updatedHistory));
    setHistoryAssets(updatedHistory);
  }, [assetInfo]);

  useEffect(() => {
    if (marketData.length === 0) return;

    const history = getHistoryAssets();
    const updatedHistory = history.filter((asset) => {
      const marketAsset = marketData[0]?.settings.find((market) => market._id === asset._id);
      if (marketAsset === undefined || !marketAsset.isVisible) return false;
      return true;
    });

    localStorage.setItem('history-assets', JSON.stringify(updatedHistory));
    setHistoryAssets(updatedHistory);
  }, [marketData]);

  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleNewsToggle = (value: boolean): void => {
    setIsNewsShown(value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        {historyAssets.map((asset) => (
          <Link to={`/trade?asset=${asset.symbol}`} className={`${styles.assetChip} ${asset.selected ? styles.selected : ''}`} key={asset._id}>
            {asset.selected && <CheckIcon sx={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }} />}
            <span>{asset.name}</span>
            {!asset.selected && (
              <button onClick={(e) => { handleRemoveAsset(e, asset._id); }}>
                <CloseIcon sx={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/>
              </button>
            )}
          </Link>
        ))}
      </div>
      <button className={styles.toggleBtn} onClick={openMenu}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0V8H8V0H0ZM6 6H2V2H6V6ZM0 10V18H8V10H0ZM6 16H2V12H6V16ZM10 0V8H18V0H10ZM16 6H12V2H16V6ZM10 10V18H18V10H10ZM16 16H12V12H16V16Z" fill="var(--CFD-theme-System-OnSurface)"/>
        </svg>
      </button>
      <CustomMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <span className={styles.menuHeaderText}>{t('trading_page.chart.setting_modal.subtitle')}:</span>
        <div className={styles.inputRow}>
          <CheckboxComponent checked={isMarketShown} id='market_checkbox' onChange={({ target: { checked } }) => { setIsMarketShown(checked) }}/>
          <label htmlFor='market_checkbox'>{t('trading_page.chart.setting_modal.labels.view_market')}</label>
        </div>
        <div className={styles.inputRow}>
          <CheckboxComponent checked={isNewsShown} id='news_checkbox' onChange={({ target: { checked } }) => { handleNewsToggle(checked) }}/>
          <label htmlFor='news_checkbox'>{t('trading_page.chart.setting_modal.labels.view_news')}</label>
        </div>
      </CustomMenu>
    </div>
  )
}
