import { configureStore } from '@reduxjs/toolkit';
import { withdrawalTypesApi } from './api/withdrawalTypesApi';
import { depositTypesApi } from './api/depositTypesApi';

import initReducer from './reducers/init';
import loginReducer from './reducers/login';
import languageReducer from './reducers/language';
import userReducer from './reducers/user';
import profileReducer from './reducers/profile';
import profileSettingsReducer from './reducers/profileSettings';
import kycVerificationReducer from './reducers/kycVerification';
import twoFactorAuthenticationReducer from './reducers/twoFactorAuthentication';
import countriesReducer from './reducers/countries';
import cfdReducer from './reducers/cfd';
import ordersReducer from './reducers/orders';
import newsReducer from './reducers/news';
import accountsReducer from './reducers/accounts';
import transactionsHistoryReducer from './reducers/transactionsHistory';
import currencyRatesReducer from './reducers/currencyRates';
import chatReducer from './reducers/chat';
import wallerReducer from './reducers/wallet';
import currenciesReducer from './reducers/currencies';
import pspReducer from './reducers/psp';
import savingReducer from './reducers/saving';
import savingInvestmentsReducer from './reducers/savingInvestment';
import savingAccountsReducer from './reducers/savingAccounts';
import withdrawalMethodsReducer from './reducers/withdrawalMethods';
import networksReducer from './reducers/networks';
import showFooterReducer from './reducers/showFooter';
import tradingModeReducer from './reducers/tradingMode';
import paymentService from './reducers/paymentService';
import savingOptionsReducer from './reducers/savingOptions';
import autotraderReducer from './reducers/autotrader';
import modalReducer from './reducers/modalSlice';
import alertMessageReducer from './reducers/alertMessage';
import kycTypesReducer from './reducers/kycTypes';
import withdrawalTypesReducer from './reducers/withdrawalTypes';
import depositTypesReducer from './reducers/depositTypes';
import marketDataReducer from './reducers/marketData';
import socketDataReducer from './reducers/socketData';
import userWithdrawalTypesReducer from './reducers/userWithdrawalTypes';

export const store = configureStore({
  reducer: {
    init: initReducer,
    login: loginReducer,
    language: languageReducer,
    user: userReducer,
    profile: profileReducer,
    profileSettings: profileSettingsReducer,
    kycVerification: kycVerificationReducer,
    twoFactorAuthentication: twoFactorAuthenticationReducer,
    countries: countriesReducer,
    cfd: cfdReducer,
    orders: ordersReducer,
    news: newsReducer,
    accounts: accountsReducer,
    transactionsHistory: transactionsHistoryReducer,
    currencyRates: currencyRatesReducer,
    chat: chatReducer,
    wallet: wallerReducer,
    currencies: currenciesReducer,
    psp: pspReducer,
    saving: savingReducer,
    savingInvestments: savingInvestmentsReducer,
    savingAccounts: savingAccountsReducer,
    withdrawalMethods: withdrawalMethodsReducer,
    networks: networksReducer,
    showFooter: showFooterReducer,
    tradingMode: tradingModeReducer,
    paymentServices: paymentService,
    savingOptions: savingOptionsReducer,
    autotrader: autotraderReducer,
    modal: modalReducer,
    alertMessage: alertMessageReducer,
    kycTypes: kycTypesReducer,
    [withdrawalTypesApi.reducerPath]: withdrawalTypesApi.reducer,
    [depositTypesApi.reducerPath]: depositTypesApi.reducer,
    depositTypes: depositTypesReducer,
    withdrawalTypes: withdrawalTypesReducer,
    marketData: marketDataReducer,
    socketData: socketDataReducer,
    userWithdrawalTypes: userWithdrawalTypesReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(withdrawalTypesApi.middleware)
      .concat(depositTypesApi.middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
