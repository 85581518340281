import React, { type FC, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { type SelectChangeEvent } from '@mui/material';
import { useAppSelector } from '../../../../hooks/redux';
import { pspBankAddressSelector } from '../../../../redux/selectors/pspSelector';
import {
  AssetAmountInputComponent,
  SelectComponent,
  ButtonComponent,
  InputComponent
} from '../../../../components';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { BankDepositInvoice } from './BankDepositInvoice';
import { generatePdf } from '../../../../helpers/generatePdf';

import style from './BankDepositFormComponent.module.css';

interface InterfaceBankDepositFormComponent {
  setStep: (el: number) => void
  step: number
  setOpen: (el: boolean) => void
  selectedAccount: IAccount | null
}

export const BankDepositFormComponent: FC<InterfaceBankDepositFormComponent> = ({ selectedAccount }) => {
  const bankData = useAppSelector(pspBankAddressSelector);

  const [amount, setAmount] = useState<string>('');
  const [bankValue, setBankValue] = useState({
    name: '',
    iban: '',
    accountNumber: '',
    bankAddress: '',
    swiftCode: '',
    currencyName: '',
    beneficiary: '',
    beneficiaryAddress: '',
    correspondentAccount: '',
    inn: '',
    kpp: '',
    reference: '',
    _id: ''
  });

  const { customId } = useAppSelector((state) => state.user);

  const bankDataOptions = useMemo(() => {
    return bankData.map((bank) => ({ label: `${bank.name}-${bank.currencyName}`, value: bank._id }))
  }, [bankData]);

  const renderBankInput = (
    label: string, value: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, error?: boolean
  ): JSX.Element | null => {
    if (value.length > 1) {
      return (
        <div className={style.inputContainer}>
          <label className={style.label}>{t(label)}</label>
          <InputComponent
            value={value}
            isCopy
            placeholder={t(label)}
            onChange={onChange}
            error={error}
          />
        </div>
      );
    }
    return null;
  }

  const now = new Date();
  const formattedDate = now.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });

  const userName = `${selectedAccount?.user.firstName} ${selectedAccount?.user.lastName}`;

  const onDownloadPdf = (): void => {
    generatePdf({ scale: 2, elementId: 'invoice', fileName: 'invoice' });
  }

  useEffect(() => {
    if (bankData === undefined || bankData.length === 0) return;
    const [firstBank] = bankData;
    setBankValue({
      name: firstBank.name,
      iban: firstBank.iban,
      accountNumber: firstBank.accountNumber,
      bankAddress: firstBank.bankAddress,
      swiftCode: firstBank.swiftCode,
      currencyName: firstBank.currencyName,
      beneficiary: firstBank.beneficiary,
      beneficiaryAddress: firstBank.beneficiaryAddress,
      correspondentAccount: firstBank.correspondentAccount,
      inn: firstBank.inn,
      kpp: firstBank.kpp,
      reference: firstBank.reference,
      _id: firstBank._id
    });
  }, [bankData])

  const invoiceData = {
    userName,
    formattedDate,
    currencySymbol: bankValue.currencyName,
    amount,
    accountNumber: bankValue.accountNumber,
    bankName: bankValue.name,
    swift: bankValue.swiftCode,
    iban: bankValue.iban,
    bankAddress: bankValue.bankAddress,
    beneficiary: bankValue.beneficiary,
    beneficiaryAddress: bankValue.beneficiaryAddress,
    correspondentAccount: bankValue.correspondentAccount,
    inn: bankValue.inn,
    kpp: bankValue.kpp,
    reference: bankValue.reference,
    customId: String(customId) ?? ''
  };

  const selectHandler = (event: SelectChangeEvent<unknown>): void => {
    const { target: { value } } = event;
    const selectedBankData = bankData?.find(({ _id }) => _id === value);
    if (selectedBankData !== undefined) setBankValue(selectedBankData);
  }

  return (
    <>
     {
       <>
          <div className={ style.wrapper }>
            {
              bankData?.length > 1 && <div className={ style.selectWrapper }>
                <label className={ style.label } htmlFor="bank">{t('labels.select_bank')}</label>
                <SelectComponent options={bankDataOptions} value={bankValue._id} id="bank" onChange={selectHandler}/>
              </div>
            }
            <div className={ style.bankWrapper }>
              {bankData?.length > 0 && <p className={ style.bankWrapperText }>1. {t('deposit_modal.bank_form.subtitle')}</p>}
              {renderBankInput('bankData.account_number', bankValue.accountNumber, () => {})}
              {renderBankInput('bankData.iban', bankValue.iban, () => {})}
              {renderBankInput('bankData.bank_name', bankValue.name, () => {})}
              {renderBankInput('bankData.beneficiary', bankValue.beneficiary, () => {})}
              {renderBankInput('bankData.beneficiary_address', bankValue.beneficiaryAddress, () => {})}
              {renderBankInput('bankData.correspondent_account', bankValue.correspondentAccount, () => {})}
              {renderBankInput('bankData.reference', bankValue.reference, () => {})}
              {renderBankInput('bankData.swift_code', bankValue.swiftCode, () => {})}
              {renderBankInput('bankData.inn', bankValue.inn, () => {})}
              {renderBankInput('bankData.kpp', bankValue.kpp, () => {})}
              {renderBankInput('bankData.bank_address', bankValue.bankAddress, () => {})}
            </div>
            {bankData?.length > 0 && <div className={ style.bankWrapper }>
              <p className={ style.bankWrapperText }>2. {t('labels.enter_amount')}</p>
              <div className={ style.inputContainer }>
                <label htmlFor='amount' className={ style.label }>{t('labels.amount')}</label>
                <AssetAmountInputComponent value={ amount } setValue={ setAmount } id='amount' currency={bankValue.currencyName ?? selectedAccount?.cfdAccountCurrency.symbol} maxBtn={ false } placeholder='0.00'/>
              </div>
            </div>}
          </div>
          {bankData?.length > 0 &&
          <footer className={ style.footer }>
            <div className={ style.btnContainer }>
              <ButtonComponent onClick={ onDownloadPdf } variant='contained' disabled={amount.trim().length === 0}>
                <span className={ style.btnText }>
                  {t('labels.download_pdf')}
                </span>
              </ButtonComponent>
            </div>
          </footer>}
        </>
      }
        <BankDepositInvoice {...invoiceData} />
    </>
  )
}
