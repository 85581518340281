import React from 'react';
import { Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CheckboxInterface {
  id?: string
  name?: string
  disabled?: boolean
  checked?: boolean
  error?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CustomizedCheckbox = styled(Checkbox)<{ error?: boolean }>(({ error }) => ({
  color: 'var(--CFD-theme-System-OnSurface)',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_08)',
    cursor: 'pointer'
  },
  '&.Mui-checked': {
    color: 'var(--CFD-theme-System-Tertiary)',
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  '&.Mui-disabled': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    opacity: '0.38'
  },
  '&.MuiCheckbox-root.Mui-error': {
    color: error ? 'var(--CFD-theme-System-Error)' : '',
    '&:hover': {
      backgroundColor: 'var(--CFD-theme-System-OnError)',
      cursor: 'pointer'
    }
  }
}));

export const CheckboxComponent: React.FC<CheckboxInterface> = ({
  disabled = false,
  error = false,
  ...rest
}) => {
  return <CustomizedCheckbox disabled={disabled} error={error} {...rest} />;
};
