import React from 'react';
import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { type SwitchProps } from '@mui/material/Switch/Switch';

interface SwitchInterface extends SwitchProps {
  id?: string
  name?: string
  disabled?: boolean
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CustomizedSwitch = styled(Switch)(() => ({
  width: 52,
  height: 32,
  padding: 0,
  '&:hover': {
    '& .MuiSwitch-thumb': {
      color: 'var(--CFD-theme-System-OnSurfaceVariant)'
    },
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& .MuiSwitch-thumb': {
          color: 'var(--CFD-theme-System-TertiaryContainer)'
        }
      }
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        backgroundColor: 'var(--CFD-theme-System-Tertiary)',
        opacity: 1,
        border: 0
      },
      '& .MuiSwitch-thumb': {
        width: 24,
        height: 24,
        margin: '4px 5px 4px 0',
        color: 'var(--CFD-theme-System-OnTertiary)'
      }
    },
    '&.Mui-checked.Mui-disabled': {
      '& .MuiSwitch-thumb': {
        color: 'var(--CFD-theme-System-Surface)',
        opacity: 1
      },
      '& + .MuiSwitch-track': {
        backgroundColor: 'var(--CFD-theme-State-layers-on-surface-opacity_12)'
      }
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'var(--CFD-theme-System-OnBackground)',
      opacity: 0.38
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: 'var(--CFD-theme-System-SurfaceVariant)'
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    margin: 8,
    color: 'var(--CFD-theme-System-Outline)',
    boxShadow: 'none'
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: 'var(--CFD-theme-Surface-5)',
    border: '2px solid var(--CFD-theme-System-Outline)',
    boxSizing: 'border-box'
  }
}));

export const SwitchComponent: React.FC<SwitchInterface> = ({ disabled = false, ...rest }) => {
  return (
    <CustomizedSwitch
      disabled={disabled}
      {...rest}
    />
  )
}
