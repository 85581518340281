import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';
import { type PayloadInterface } from '../../interfaces';
import {
  type SavingOption, type SavingInvestment, type IInitialStateSavingInvestment, type ISavingsInvestmentsTotalAmount,
  type ISavingHistoryInvestment
} from '../../interfaces/SavingInterface';

interface savingInvestmentReq {
  selectedAsset?: string
  currencyId?: string
  customCurrencyId?: string
  releaseTime?: Date
  investedAmount?: number | string
  userId?: string
  selectedOption?: SavingOption
  cfdAccountId?: string
}

interface savingInvestmentFormData {
  currencyId?: string
  customCurrencyId?: string
  releaseTime?: Date
  investedAmount?: number
  userId?: string
  isFlexible?: boolean
  pct?: number
  period?: string
  cfdAccountId?: string
}

export interface IGetSavingsInvestmentQuery {
  currencyId?: string
  isFlexible?: string | boolean
  createdDate?: string | Date
  status?: string
  period?: string
  sortField?: string
  sortOrder?: string
}

export interface IGetSavingsHistoryInvestmentQuery {
  redeemDate?: string | Date
  savingAccount?: string
  redeemToAccount?: string
  sortField?: string
  sortOrder?: string
}

const initialState: IInitialStateSavingInvestment = {
  savingsInvestmentsData: [],
  savingsHistoryInvestmentData: [],
  savingsInvestmentsTotalAmount: {
    totalInvested: 0,
    averagePercentage: 0,
    cumulativeTotal: 0
  }
};

export const getTotalSavingsInvestmentsAmount = createAsyncThunk(
  'saving-investments-total-amount/get',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/saving-investment/total-invested/${userId}` });

    return response.data.totalSavingInvestment;
  }
);

export const getSavingInvestmentsAccounts = createAsyncThunk(
  'saving-investments/get',
  async ({ userId, query }: { userId: string, query: IGetSavingsInvestmentQuery }) => {
    let url = `/saving-investment/accounts-table/${userId}`;

    for (const [key, value] of Object.entries(query)) {
      url += url.includes('?') ? `&${key}=${value}` : `?${key}=${value}`;
    }

    const response = await apiHelper({ method: 'get', path: `${url}` });

    return response.data.savingInvestmentsTable;
  }
);

export const getSavingInvestmentsHistory = createAsyncThunk(
  'saving-investments/get-history',
  async ({ userId, query }: { userId: string, query: IGetSavingsHistoryInvestmentQuery }) => {
    let url = `/saving-investment/history-table/${userId}`;

    for (const [key, value] of Object.entries(query)) {
      url += url.includes('?') ? `&${key}=${value}` : `?${key}=${value}`;
    }

    const response = await apiHelper({ method: 'get', path: `${url}` });

    return response.data.savingInvestmentsHistoryTable;
  }
);

export const createSavingInvestment = createAsyncThunk(
  'saving-investments/create',
  async ({ userId, currencyId, customCurrencyId, selectedOption, investedAmount, releaseTime, cfdAccountId }: savingInvestmentReq, { rejectWithValue }) => {
    try {
      const data: savingInvestmentFormData = {
        userId,
        currencyId,
        customCurrencyId,
        investedAmount: Number(investedAmount)
      }

      if (selectedOption !== undefined) {
        if (selectedOption.isFlexible) data.isFlexible = selectedOption.isFlexible;
        if (selectedOption.percent !== undefined) data.pct = selectedOption.percent;
        if (selectedOption.period !== undefined && selectedOption.durationType !== undefined) {
          data.period = `${selectedOption.period} ${selectedOption.durationType}`;
        };

        if (!selectedOption.isFlexible) {
          data.releaseTime = releaseTime;
        };
      }

      if (cfdAccountId !== undefined) data.cfdAccountId = cfdAccountId

      const response = await apiHelper({ method: 'post', path: '/saving-investment/add', data });

      return response.data.savingInvestment;
    } catch (error: any) {
      const { data } = error.response;

      const errorPayload: PayloadInterface = {
        error: data.message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const redeemSavingInvestment = createAsyncThunk(
  'saving-investments/redeem',
  async ({ savingInvestmentId, currencyId }: { savingInvestmentId: string, currencyId: string }, { rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'patch', path: `/saving-investment/redeem/${savingInvestmentId}`, data: { cfdAccountId: currencyId } });

      return response.data.savingInvestment;
    } catch (error: any) {
      const { data } = error.response;

      const errorPayload: PayloadInterface = {
        error: data.message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
)

export const claimFlexibleSavingInvestment = createAsyncThunk(
  'saving-investments/claim-flexible',
  async ({ id, cfdAccountId }: { id: string, cfdAccountId: string }, { rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'patch', path: `/saving-investment/claim-flexible/${id}`, data: { cfdAccountId } })
      return response.data.savingInvestment;
    } catch (error: any) {
      const { data } = error.response;

      const errorPayload: PayloadInterface = {
        error: data.message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
)

export const savingInvestmentsSlice = createSlice({
  name: 'saving-investments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSavingInvestmentsAccounts.fulfilled, (state, action: PayloadAction<SavingInvestment[]>) => {
      state.savingsInvestmentsData = action.payload
    });
    builder.addCase(createSavingInvestment.fulfilled, (state, action: PayloadAction<SavingInvestment>) => {
      state.savingsInvestmentsData.unshift(action.payload as never);
    });
    builder.addCase(redeemSavingInvestment.fulfilled, (state, action: PayloadAction<SavingInvestment>) => {
      state.savingsInvestmentsData = state.savingsInvestmentsData.filter((item) => item._id !== action.payload._id);
    });
    builder.addCase(claimFlexibleSavingInvestment.fulfilled, (state, action: PayloadAction<SavingInvestment>) => {
      state.savingsInvestmentsData = state.savingsInvestmentsData.filter((item) => item._id !== action.payload._id);
    });
    builder.addCase(getTotalSavingsInvestmentsAmount.fulfilled, (state, action: PayloadAction<ISavingsInvestmentsTotalAmount>) => {
      state.savingsInvestmentsTotalAmount = action.payload;
    });
    builder.addCase(getSavingInvestmentsHistory.fulfilled, (state, action: PayloadAction<ISavingHistoryInvestment[]>) => {
      state.savingsHistoryInvestmentData = action.payload;
    });
  }
});

export default savingInvestmentsSlice.reducer;
