import React, { type FC, useState, useEffect, useMemo, type FocusEvent, type ChangeEvent } from 'react';
import { t } from 'i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getBanks, addBank } from '../../../../redux/reducers/withdrawalMethods';
import { getCurrencyRates } from '../../../../redux/reducers/currencyRates';
import { createExternalTransaction, getTransactionsHistory } from '../../../../redux/reducers/transactionsHistory';
import { toFixed } from '../../../../helpers/util';
import {
  AssetAmountInputComponent,
  ButtonComponent,
  CheckboxComponent
} from '../../../../components';
import { SavedBankWithdrawalOptions } from './SavedBankWithdrawalOptions/SavedBankWithdrawalOptions';
import { SuccessWithdrawalSection } from '../SuccessWithdrawalSection/SuccessWithdrawalSection';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { AddBankFormComponent } from '../AddBankFormComponent/AddBankFormComponent';
import { WarningBetweenSymbolComponent } from '../WarningBetweenSymbolComponent/WarningBetweenSymbolComponent';
import { useWithdrawalDispatchTransaction } from '../../../../hooks/useWithdrawalDispatchTransaction';
import { type OptionInterface } from '../../../../interfaces';

import style from './BankWithdrawalSection.module.css';

export interface TypeBankOption { swift: string, iban: string, bankName: string }

interface InterfaceBankWithdrawSection {
  setOpen: (el: boolean) => void
  setStep: (el: number) => void
  step: number
  selectedAccount: IAccount | null
}

interface IAddBankData {
  name: string
  iban: string
  accountNumber: string
  bankAddress: string
  swiftCode: string
  currencyId: string
  userId: string
}

interface ICreateWithdrawalBankData {
  userId: string
  currencyId: string
  userAccountId: string
  sendToIban: string
  sendToAddress: string
  sendToAccountNumber: string
  sendToSwiftCode: string
  amount: number
  amountFromAccount: string | number
  type: 'Bank'
}

export interface IBankData {
  bankName: string
  iban: string
  bankAddress: string
  accountNumber: string
  swift: string
}

export const BankWithdrawSection: FC<InterfaceBankWithdrawSection> = ({ setOpen, setStep, step, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const [bankSelect, setBankSelect] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
  const [amount, setAmount] = useState<string>('');
  const [currencySelected, setCurrencySelected] = useState<OptionInterface>({
    value: '',
    label: '',
    symbol: ''
  });
  const [bankData, setBankData] = useState<IBankData>({
    bankName: '',
    iban: '',
    bankAddress: '',
    accountNumber: '',
    swift: ''
  });
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const [isAddBank, setIsAddBank] = useState<boolean>(false);
  const { executeTransaction } = useWithdrawalDispatchTransaction(setIsBtnLoading);

  const { banks } = useAppSelector((state) => state.withdrawalMethods);
  const { _id: userId } = useAppSelector((state) => state.user);
  const { rates: currencyRates } = useAppSelector((state) => state.currencyRates);
  const currencies = useAppSelector((state) => state.currencies);

  const validBankAccount = (
    bankData.bankName.length > 0 &&
    bankData.iban.length > 0 &&
    bankData.accountNumber.length > 0 &&
    bankData.bankAddress.length > 0 &&
    bankData.swift.length > 0 &&
    currencySelected.value.length > 0
  );

  const selectedBank = useMemo(() => {
    return banks.find(({ _id }) => _id === bankSelect) ?? null;
  }, [bankSelect])

  const formSelectedCurrency = useMemo(() => {
    return currencies.find(({ _id }) => _id === currencySelected.value)?.symbol ?? null;
  }, [currencySelected])

  useEffect(() => {
    if (userId === undefined) return;

    dispatch(getBanks(userId));
  }, []);

  useEffect(() => {
    if (banks.length > 0 && banks !== undefined) {
      const [{ _id }] = banks;
      setBankSelect(_id);
    }
  }, [banks])

  useEffect(() => {
    if (selectedAccount !== null) dispatch(getCurrencyRates(selectedAccount.cfdAccountCurrency.symbol));
  }, [selectedAccount]);

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    setBankSelect('');
  };

  const checkBoxHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCheckboxValue(!checkboxValue);
    setIsAddBank(!isAddBank);
  }

  const handleWithdrawlClick = async (): Promise<void> => {
    let formBankData: Record<string, string> = {};
    if (validBankAccount) {
      formBankData = {
        name: bankData.bankName,
        iban: bankData.iban,
        accountNumber: bankData.accountNumber,
        bankAddress: bankData.bankAddress,
        swiftCode: bankData.swift,
        currencyId: currencySelected.value,
        userId: userId ?? ''
      }
    }

    const validSelectedAccount = (selectedBank === null && !validBankAccount)

    if (userId === undefined || validSelectedAccount || selectedAccount === null) return;

    setIsBtnLoading(true);
    const currencySymbol = (selectedBank != null) ? selectedBank.currencyId?.symbol : formSelectedCurrency;

    const bankAccount = ((isAddBank || validBankAccount) && (selectedBank == null)) ? formBankData : selectedBank ?? {};

    if (isAddBank) {
      const addBankData: IAddBankData = {
        name: bankData.bankName,
        iban: bankData.iban,
        accountNumber: bankData.accountNumber,
        bankAddress: bankData.bankAddress,
        swiftCode: bankData.swift,
        currencyId: currencySelected.value,
        userId
      };

      const addBankSuccess = await executeTransaction(addBank, addBankData);
      if (!addBankSuccess) return;
    }

    const createWithdrawalBankData = (): ICreateWithdrawalBankData => {
      return {
        userId,
        currencyId: selectedBank?.currencyId?._id ?? currencySelected?.value,
        userAccountId: selectedAccount._id,
        sendToIban: bankAccount?.iban,
        sendToAddress: bankAccount?.bankAddress,
        sendToAccountNumber: bankAccount?.accountNumber,
        sendToSwiftCode: bankAccount?.swiftCode,
        amount: Number(amount),
        amountFromAccount: amount,
        type: 'Bank'
      };
    };

    const withdrawalData = createWithdrawalBankData();

    const transactionSuccess = await executeTransaction(createExternalTransaction, withdrawalData);
    if (!transactionSuccess) return;

    dispatch(getTransactionsHistory({ userId, accountId: selectedAccount._id }))
    setStep(2);
  }

  const handleCloseWithdrawal = (): void => {
    setOpen(false);
  }

  const savedBankHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setBankSelect(event.target.value);
    setBankData({
      bankName: '',
      iban: '',
      bankAddress: '',
      accountNumber: '',
      swift: ''
    });
    setCurrencySelected({
      value: '',
      label: '',
      symbol: ''
    });
    setCheckboxValue(false);
    setIsAddBank(false);
  }

  const amountComplete = (amount.length > 0 && Number(amount) > 0);
  const disableBtn = (validBankAccount || (selectedBank != null)) && amountComplete;
  const ibanView = (isAddBank || validBankAccount) ? bankData.iban : selectedBank?.iban;
  const currencyViewSymbol = selectedBank?.currencyId.symbol ?? formSelectedCurrency;
  const bankNameView = (isAddBank || validBankAccount) ? bankData.bankName : selectedBank?.bankAddress;
  const distinctRateView = currencyViewSymbol === selectedAccount?.cfdAccountCurrency?.symbol
    ? currencyViewSymbol
    : `${currencyViewSymbol} ${t('labels.to').toLocaleLowerCase()} ${selectedAccount?.cfdAccountCurrency?.symbol}`
  const isShowWarning = (currencyViewSymbol !== null && (currencyViewSymbol !== selectedAccount?.cfdAccountCurrency.symbol));

  return (
    <>
      { (step === 1)
        ? <>
            <div className={ style.wrapper }>
              <div className={ style.cardWrapper }>
                <p className={ style.cardWrapperText }>{t('labels.amount_to_withdrawal')}</p>
                <div className={ style.inputWrapper }>
                  <AssetAmountInputComponent
                    value={ amount }
                    setValue={ setAmount }
                    id='amount'
                    currency={selectedBank?.currencyId?.symbol ?? currencySelected?.symbol ?? ''}
                    maxBtn={ false }
                    placeholder='0.00'
                    showCurrency={false}
                  />
                </div>
              </div>
              { (banks.length !== 0) && <div className={ style.cardWrapper }>
                  <div className={ style.savedCardsWrapper }>
                    <p className={ style.cardWrapperText }>
                      {t('labels.choose_saved_bank_acc')}
                    </p>
                    <SavedBankWithdrawalOptions selectedOptions={ bankSelect } setSelectedOptions={ setBankSelect } options={ banks } onChange={ savedBankHandler }/>
                  </div>
              </div>}
              <div
                className={ style.cardWrapper }
                style={{ paddingBottom: isShowWarning ? '32px' : '6px' }}
              >
                <p className={ style.cardWrapperText }>{t('labels.bank_account_detail')}</p>
                <AddBankFormComponent
                  setBankData={ setBankData }
                  bankData={ bankData }
                  currencySelected={ currencySelected }
                  setCurrencySelected={ setCurrencySelected }
                  onFocus={ handleFocus }
                />
                <div className={style.checkboxContainer}>
                  <CheckboxComponent disabled={ !validBankAccount } checked={ checkboxValue } onChange={ checkBoxHandler }/>
                  <p className={style.checkboxTitle}>{t('withdrawal_modal.save_bank_for_future')}</p>
                </div>
              </div>
            </div>
            <footer className={ style.footer }>
              {
                isShowWarning && (
                  <WarningBetweenSymbolComponent selectedAccount={ selectedAccount?.cfdAccountCurrency?.symbol ?? 'USD' } currencySymbol={ currencyViewSymbol ?? 'USD' } sectionName='Bank' />
                )
              }
              <div className={ style.btnContainer }>
                <ButtonComponent onClick={() => { handleWithdrawlClick(); } } disabled={ !disableBtn } loading={isBtnLoading}>
                  <span className={ style.btnText }>
                    {t('withdrawal_modal.add_bank_form_modal.footer.action_button_text')}
                  </span>
                </ButtonComponent>
              </div>
            </footer>
          </>
        : <SuccessWithdrawalSection
            title={t('withdrawal_modal.success_title')}
            amount={`${toFixed(Number(amount), 2)}`}
            currency={currencyViewSymbol}
            bankName={bankNameView ?? ''}
            iban={ibanView?.toUpperCase()}
            onClick={ handleCloseWithdrawal }
            sectionName='Bank'
            distinctRate={distinctRateView}
          />
      }
    </>
  )
}
