import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { setAutotraderId } from './user';
import { apiHelper } from '../api/apiHelper';

export interface IAutotrader {
  _id: string
  userId: string
  riskLevel: string
  isEnabled: boolean
}

const initialState: IAutotrader = {
  _id: '',
  userId: '',
  riskLevel: 'low',
  isEnabled: false
};

export const getAutotrader = createAsyncThunk(
  'autotrader/get',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/autotrader/query?userId=${userId}` });

    return response.data.autotrader;
  }
);

export const updateAutotrader = createAsyncThunk(
  'autotrader/update',
  async ({ userId, riskLevel, isEnabled }: { userId: string, riskLevel: string, isEnabled: boolean }) => {
    const response = await apiHelper({ method: 'patch', path: `/autotrader/${userId}`, data: { isEnabled, riskLevel } });

    return response.data.autotrader;
  }
);

export const autotraderSlice = createSlice({
  name: 'autotrader',
  initialState,
  reducers: {
    setAutotrader (state, action: PayloadAction<IAutotrader>) {
      return action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateAutotrader.fulfilled, (state, action: PayloadAction<IAutotrader>) => {
      return action.payload;
    });
    builder.addCase(getAutotrader.fulfilled, (state, action: PayloadAction<IAutotrader>) => {
      return action.payload;
    });
  }
});

export const { setAutotrader } = autotraderSlice.actions;

export default autotraderSlice.reducer;
