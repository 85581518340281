import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { styled } from '@mui/material/styles';

import { type IAccardionHistoryMainContent, type StyledAccordionSummaryPropsHistory } from '../../../../interface/savingsPageInterfaces';
import { formatDate } from '../../../../../../helpers/util';

import styles from './AccardionMobileMainContent.module.css';

const StyledAccordionSummary = styled(AccordionSummary)<StyledAccordionSummaryPropsHistory>(({ expanded = 'false' }) => {
  if (expanded === 'true') {
    return {
      backgroundColor: 'var(--CFD-theme-Surface-3) !important',
      color: 'var(--CFD-theme-System-OnSurface)',
      borderTop: '1px solid var(--CFD-theme-System-SecondaryContainer)',
      padding: '0 !important',
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      '& .MuiAccordionSummary-content': {
        margin: '0 !important',
        height: '100%'
      },
      '&:hover': {
        backgroundColor: 'color-mix(in srgb, var(--CFD-theme-Surface-3), var(--CFD-theme-State-layers-on-surface-opacity_12) 12%)'
      }
    }
  }

  return {
    backgroundColor: 'var(--CFD-theme-Surface-1) !important',
    color: 'var(--CFD-theme-System-OnSurface)',
    borderTop: '1px solid var(--CFD-theme-System-SecondaryContainer)',
    padding: '0 !important',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    '& .MuiAccordionSummary-content': {
      margin: '0 !important',
      height: '100%'
    },
    '&:hover': {
      backgroundColor: 'color-mix(in srgb, var(--CFD-theme-Surface-3), var(--CFD-theme-State-layers-on-surface-opacity_12) 12%)'
    }
  }
})

export const AccardionMobileMainContent: FC<IAccardionHistoryMainContent> = ({ expanded, onClick, row }) => {
  const { t } = useTranslation();
  const { createdAt, pct, cumulativeTotal, releaseTime, currency, customCurrency, cfdAccountTypeName, cfdAccountCurrencySymbol } = row;
  return (
    <Accordion
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          border: 'none',
          backgroundColor: 'transparent',
          '&.MuiPaper-root': {
            backgroundColor: 'var(--CFD-theme-Surface-3)'
          },
          '@media (max-width: 900px)': {
            width: '100% !important'
          }
        }}
      >
        <StyledAccordionSummary
          aria-controls="panel1bhy-content"
          id="panel1bhy-header"
          onClick={ onClick }
          expanded={`${expanded}`}
        >
          <div className={ styles.accardionMainWrapper }>
            <p className={ styles.accardionMainText }>{ currency ? `(${currency.symbol}) ${currency.name}` : customCurrency?.name ?? '' }</p>
            <p className={ styles.accardionMainText }>{ `${cumulativeTotal} ${currency ? currency.symbol : customCurrency?.name ?? ''}` }</p>
          </div>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div className={ styles.accardionContentWrapper }>
            <div className={ styles.accardionContentRow }>
              <p className={ styles.accardionContentRowTitle }>{ t('savings_page.subscribe_modal.Subscription_date') }</p>
              <p className={ styles.accardionContentRowText }>{ formatDate(new Date(createdAt)) }</p>
            </div>
            <div className={ styles.accardionContentRow }>
              <p className={ styles.accardionContentRowTitle }>{ t('savings_page.table_body.accounts_tab.accordion_details.rate_title') }</p>
              <p className={ styles.accardionContentRowText }>{ `${pct}%` }</p>
            </div>
            <div className={ styles.accardionContentRow }>
              <p className={ styles.accardionContentRowTitle }>{ t('labels.redeem_date') }</p>
              <p className={ styles.accardionContentRowText }>{ formatDate(new Date(releaseTime)) }</p>
            </div>
            <div className={ styles.accardionContentRow }>
              <p className={ styles.accardionContentRowTitle }>{ t('labels.redeem_to_account') }</p>
              <p className={ styles.accardionContentRowText }>
              { (cfdAccountTypeName && cfdAccountTypeName)
                ? `${cfdAccountTypeName} ${t('labels.account')} ${cfdAccountCurrencySymbol}`
                : '-'
              }
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
  )
}
