/* eslint-disable @typescript-eslint/strict-boolean-expressions */
export const parseFullSymbol = (fullSymbol: string): { exchange: string, fromSymbol: string, toSymbol?: string } | null => {
  let match = fullSymbol.match(/^([\w\s.]+):([\w\s.]+)\/([\w\s.]+)$/);

  if (!match) {
    match = fullSymbol.match(/^([\w\s.]+):([\w\s.]+)$/);
  }

  if (!match) {
    match = fullSymbol.match(/^([\w\s.]+)$/);
  }

  if (!match) {
    return null;
  }

  const result = { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
  return result;
};
