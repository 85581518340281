import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from './redux';
import { AuthData } from '../auth/AuthWrapper';

const useAutoLogout = (): void => {
  const { logout } = AuthData();

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [timeout, setTimeoutValue] = useState<number | null>(null);
  const { autoLogoutTime, _id: userId } = useAppSelector((state) => state.user);

  const minutesToMilliseconds = (minutes: number): number => {
    return minutes * 60 * 1000;
  };

  useEffect(() => {
    if (autoLogoutTime !== undefined && !Number.isNaN(autoLogoutTime)) {
      setTimeoutValue(minutesToMilliseconds(autoLogoutTime));
    }
  }, [autoLogoutTime]);

  const resetTimer = (): void => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
    }

    if (timeout !== null && userId !== undefined) {
      timerRef.current = setTimeout(() => {
        logout(userId);
      }, timeout);
    }
  };

  useEffect(() => {
    const handleActivity = (): void => {
      resetTimer();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);

    return () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, [timeout]);
};

export default useAutoLogout;
