import React, { type FC } from 'react';
import { ModalContext } from '../../components';
import { HeaderModalTransferFunds } from './Header/HeaderModalTransferFunds';
import { SectionModalTransferFunds } from './Section/SectionModalTransferFunds';
import { FooterModalTransferFunds } from './Footer/FooterModalTransferFunds';
import { type IAccount } from '../../redux/reducers/accounts';

interface InterfaceModalTransferFunds {
  open: boolean
  fromValue: number
  toValue: number
  fromSymbol: string
  toSymbol: string
  isLoading: boolean
  step: number
  onSubmit: () => Promise<void>
  setOpen: (el: boolean) => void
  handleStateAfterTransfer: () => void
  setSelectedAccount: (el: IAccount | null) => void
  fromAccount: IAccount | null
  setExpandedAccardion: (el: boolean | string) => void
}

export const ModalTransferFunds: FC<InterfaceModalTransferFunds> = ({
  open,
  setOpen,
  onSubmit,
  fromValue,
  toValue,
  fromSymbol,
  toSymbol,
  isLoading,
  step,
  handleStateAfterTransfer,
  setSelectedAccount,
  fromAccount,
  setExpandedAccardion
}) => {
  const pressOnBackground = (): void => {
    if (step !== 1) handleStateAfterTransfer()
    setOpen(false);
  }

  return (
    <ModalContext
      open={ open }
      setOpen={ setOpen }
      pressOnBackground={ pressOnBackground }
      headerComponent={ <HeaderModalTransferFunds setOpen={ setOpen } handleStateAfterTransfer={ handleStateAfterTransfer } step={ step } /> }
      sectionComponent={
        <SectionModalTransferFunds
          step={ step }
          fromValue={fromValue}
          toValue={toValue}
          fromSymbol={fromSymbol}
          toSymbol={toSymbol}
         />
      }
      footerComponent={ <FooterModalTransferFunds
        step={ step }
        setOpen={ setOpen }
        onSubmit={onSubmit}
        isLoading={isLoading}
        handleStateAfterTransfer={handleStateAfterTransfer}
        setSelectedAccount={ setSelectedAccount }
        fromAccount={ fromAccount }
        setExpandedAccardion={setExpandedAccardion}
      /> }
    />
  )
}
