import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

interface InterfaceGeneratePdf {
  elementId: string
  fileName: string
  targetWidthPx?: number
  targetHeightPx?: number
  scale?: number
}

export const generatePdf = ({
  elementId,
  fileName,
  targetHeightPx = 842,
  targetWidthPx = 595,
  scale = 2
}: InterfaceGeneratePdf): void => {
  const element = document.getElementById(elementId);

  if (element !== null) {
    html2canvas(element, { scale }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');

      const pxToMm = 0.264583333;
      const targetWidthMm = targetWidthPx * pxToMm;
      const targetHeightMm = targetHeightPx * pxToMm;

      const canvasWidthPx = canvas.width;
      const canvasHeightPx = canvas.height;

      let imgWidthMm = targetWidthMm;
      let imgHeightMm = (canvasHeightPx / canvasWidthPx) * targetWidthMm;

      if (imgHeightMm > targetHeightMm) {
        imgHeightMm = targetHeightMm;
        imgWidthMm = (canvasWidthPx / canvasHeightPx) * targetHeightMm;
      }

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'px', [targetWidthMm, targetHeightMm]);

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidthMm, imgHeightMm);

      pdf.save(`${fileName}.pdf`);
    });
  }
};
