import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './axiosBaseQuery';

export const depositTypesApi = createApi({
  reducerPath: 'depositTypesApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getDepositTypes: builder.query({
      query: ({ brandId, countryCode, userId }) => ({
        method: 'GET',
        path: `/deposit-type/active?brandId=${brandId}&countryCode=${countryCode}&userId=${userId}`
      })
    })
  })
});

export const { useGetDepositTypesQuery } = depositTypesApi;
