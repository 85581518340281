import React, { type FC } from 'react';
import { t } from 'i18next';

import { ButtonComponent } from '../../../components';
import { type IAccount } from '../../../redux/reducers/accounts';

import style from './FooterModalTransferFunds.module.css';

interface InterfaceFooterModalTransferFunds {
  step: number
  isLoading: boolean
  onSubmit: () => Promise<void>
  setOpen: (el: boolean) => void
  handleStateAfterTransfer: () => void
  setSelectedAccount: (el: IAccount | null) => void
  fromAccount: IAccount | null
  setExpandedAccardion: (el: boolean | string) => void
}

export const FooterModalTransferFunds: FC<InterfaceFooterModalTransferFunds> = ({
  step,
  setOpen,
  onSubmit,
  handleStateAfterTransfer,
  isLoading,
  fromAccount,
  setSelectedAccount,
  setExpandedAccardion
}) => {
  const confirmBtnHandler = (): void => {
    onSubmit();
    setExpandedAccardion(false);
  }

  const closeBtmHandler = (): void => {
    handleStateAfterTransfer();
    setOpen(false);
  }

  const viewHistoryBtnHandler = (): void => {
    handleStateAfterTransfer();
    if (fromAccount !== null) setSelectedAccount(fromAccount);
    setOpen(false);
  }

  return (
    <footer className={ style.footerWrapper }>
      { step === 1
        ? <div className={ style.confirmContainer }>
            <ButtonComponent onClick={ confirmBtnHandler } loading={isLoading}>{t('labels.confirm')}</ButtonComponent>
          </div>
        : <div className={ style.btnsWrapper }>
            <div className={ style.btnContainer }>
              <ButtonComponent onClick={ closeBtmHandler } variant='outlined'>{t('labels.close')}</ButtonComponent>
            </div>
            <div className={ style.btnContainer }>
              <ButtonComponent onClick={ viewHistoryBtnHandler }>{t('labels.view_history')}</ButtonComponent>
            </div>
          </div> }
    </footer>
  )
}
