import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';
import { type PayloadInterface, type SavingAccountInterface } from '../../interfaces';

const initialState: SavingAccountInterface[] = [];

export const getSavingAccounts = createAsyncThunk(
  'savingAccounts/get',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/saving-account?userId=${userId}` });

    return response.data.savingAccounts;
  }
);

export const createSavingAccount = createAsyncThunk(
  'savingAccounts/create',
  async (formData: { userId: string, currencyId?: string, customCurrencyId?: string }, { rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'post', path: '/saving-account', data: formData });

      return response.data.savingAccount;
    } catch (error: any) {
      const { data } = error.response;

      const errorPayload: PayloadInterface = {
        error: data.message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const transferSavingAccountAmount = createAsyncThunk(
  'savingAccounts/transfer',
  async (formData: { userId: string, currencyId: string }, { rejectWithValue }) => {
    try {
      const response = await apiHelper({ method: 'post', path: '/saving-account', data: formData });

      return response.data.savingAccount;
    } catch (error: any) {
      const { data } = error.response;

      const errorPayload: PayloadInterface = {
        error: data.message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const savingAccountsSlice = createSlice({
  name: 'savingAccounts',
  initialState,
  reducers: {
    decrementSavingAccountBalance (state, action: PayloadAction<Record<string, string>>) {
      state.map((item) => {
        if (item.currencyId === action.payload.currencyId) {
          item.balance = item.balance - Number(action.payload.amount)
        }
        return item;
      })
    },
    incrementSavingAccountBalance (state, action: PayloadAction<Record<string, string | undefined>>) {
      state.map((item) => {
        if (item.currencyId === action.payload.currencyId) {
          item.balance = item.balance + Number(action.payload.amount)
        }
        if (item.customCurrencyId === action.payload.customCurrencyId) {
          item.balance = item.balance + Number(action.payload.amount)
        }
        return item;
      })
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSavingAccounts.fulfilled, (state, action: PayloadAction<SavingAccountInterface[]>) => {
      return action.payload;
    });
    builder.addCase(createSavingAccount.fulfilled, (state, action: PayloadAction<SavingAccountInterface>) => {
      state.push(action.payload);
    })
  }
});

export const { decrementSavingAccountBalance, incrementSavingAccountBalance } = savingAccountsSlice.actions;
export default savingAccountsSlice.reducer;
