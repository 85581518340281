import React, { type InputHTMLAttributes, type FC, type ChangeEvent, type FocusEvent } from 'react'
import { useSnackbar } from '../Snackbar/Snackbar';
import { styled, TextField, InputAdornment } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import styles from './Input.module.css';

interface InputInterface {
  id?: string
  title?: string
  value: string
  setValue?: (el: string) => void
  placeholder?: string
  error?: boolean
  disabled?: boolean
  supportTextDynamic?: string
  helperTextStatic?: string
  sizeInput?: 'small' | 'medium'
  errorText?: string
  required?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  searchIcon?: boolean
  isCopy?: boolean
  onChange?: (el: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
}

type TInput = InputInterface & InputHTMLAttributes<HTMLInputElement>

export const CustomizedInput = styled(TextField)(({ error, focused }) => ({
  '& .MuiInputBase-root': {
    width: '100%',
    paddingLeft: '10px',
    height: '40px',
    borderRadius: '4px',
    border: '1px solid var(--CFD-theme-System-Outline)',
    outline: 'unset',

    ' .MuiInputBase-input': {
      WebkitTextFillColor: 'unset'
    },
    '&.Mui-error': {
      border: '3px solid var(--CFD-theme-System-Error)',
      margin: 0,
      '& .MuiSvgIcon-root': {
        color: !(error ?? false) ? 'var(--CFD-theme-System-Primary)' : 'var(--CFD-theme-System-Error)'
      }
    },
    '&.Mui-focused': {
      border: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: !(error ?? false) ? '3px solid var(--CFD-theme-System-Primary)' : '3px solid var(--CFD-theme-System-Error)'
      },
      '& .MuiSvgIcon-root': {
        color: !(error ?? false) ? 'var(--CFD-theme-System-OnSurfaceVariant)' : 'var(--CFD-theme-System-Error)'
      }
    },
    '&.Mui-disabled': {
      border: '1px solid var(--CFD-theme-System-Outline)',
      opacity: '0.3',
      WebkitTextFillColor: 'unset'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)'
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: 0,
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left'
  }
}))

export const InputComponent: FC<TInput> = ({
  title,
  id,
  value,
  setValue,
  placeholder,
  error,
  disabled,
  helperTextStatic,
  supportTextDynamic,
  sizeInput = 'small',
  errorText,
  required,
  variant = 'outlined',
  searchIcon = false,
  onChange,
  isCopy = false,
  onFocus,
  onBlur
}) => {
  const { handleOpen } = useSnackbar();

  let fontSize: 'small' | 'medium' = 'medium';
  if (sizeInput === 'small') fontSize = 'small';

  const handleClear = (): void => {
    if (setValue != null) setValue('');
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (setValue != null) setValue(event.target.value);
  };

  const handleCopy = (value: string): void => {
    navigator.clipboard.writeText(value);

    const openParams = {
      message: 'Text Copied',
      actionText: '',
      autoClose: true
    }

    handleOpen(openParams);
  }

  return (
    <div className={ styles.inputContainer }>
      <p className={ `${styles.inputTitle} ${(disabled ?? false) ? styles.disabled : ''}` }>{title}</p>
      <CustomizedInput
        id={ id }
        value={ value }
        onChange={ onChange ?? handleChange}
        onFocus={ onFocus }
        onBlur={ onBlur }
        placeholder={ placeholder }
        error={ error }
        disabled={ isCopy ?? disabled }
        size={ sizeInput }
        variant={ variant }
        required={ required }
        helperText= { helperTextStatic }
        sx={{
          '& input': {
            backgroundColor: 'transparent',
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px inherit inset',
              WebkitTextFillColor: 'var(--CFD-theme-System-OnSurfaceVariant) !important',
              WebkitBackgroundClip: 'text'
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              { (error ?? false) && <ErrorOutlinedIcon color='error' fontSize={ fontSize } />}
              { (!(error ?? false) && value.length > 0 && !isCopy) && <CancelOutlinedIcon onClick={ handleClear } sx={{ cursor: 'pointer' }} fontSize={ fontSize }/> }
              {isCopy && <ContentCopyIcon onClick={() => { handleCopy(value); } } sx={{ cursor: 'pointer' }} fontSize={ fontSize } />}
            </InputAdornment>
          ),
          startAdornment: (
          <InputAdornment position="start">
            {searchIcon ? <SearchOutlinedIcon sx={{ cursor: 'pointer' }} fontSize={ fontSize }/> : null }
          </InputAdornment>)
        }}
      />
      {((supportTextDynamic != null) && (value.length === 0) && !(error ?? false)) ? <p className={ `${styles.supportingText} ${(disabled ?? false) ? styles.disabled : ''}` }>{supportTextDynamic}</p> : null }
      {((error ?? false) && (errorText != null)) ? <p className={ styles.supportingTextError }>{errorText}</p> : null }
    </div>
  );
};
