import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { apiHelper } from '../api/apiHelper';

interface IAccount {
  balance: number
  credit: number
  symbol: string
  type: string
  isActive: boolean
  isEnabled: boolean
  _id: string
}

export interface IAssetQuote {
  ask: number
  bid: number
  symbol?: string
  time?: number
  marketHolidays?: boolean
}

interface IMarketData {
  _id: string
  name: string
  settings: IAssetInfo[]
}

interface IAssetInfo {
  _id: string
  label: string
  alias: string
  primary: string
  secondary: string
  lotStep: number
  lotSize: number
  leverage: number
  symbol: string
  primaryId: string
  secondaryId: string
  precision: number
  scale: number
  commission: number
  swapLong: number
  swapShort: number
  brokerSymbol: string
  type: IMarketData
  isVisible: boolean
}

interface ICFD {
  avalibleBalance: number
  investedBalance: number
  totalBalance: number
  totalCredit: number
  activeAccount: IAccount
  accounts: IAccount[]
  assets: any[]
  assetQuote: IAssetQuote
  marketData: IMarketData[]
  assetInfo: IAssetInfo
  uniqueId: number
  orderStatus: string
  hiddenPlatformColumns: string[]
  startAsset: string
  brandId?: string
  isAccountsLoading: boolean
}

const initialState: ICFD = {
  avalibleBalance: 0,
  investedBalance: 0,
  totalBalance: 0,
  totalCredit: 0,
  assets: [],
  marketData: [],
  accounts: [],
  hiddenPlatformColumns: [],
  activeAccount: { balance: 0, credit: 0, symbol: '', isActive: true, _id: '', type: '', isEnabled: true },
  assetQuote: { ask: 0, bid: 0, symbol: '', time: 0, marketHolidays: false },
  assetInfo: {
    _id: '',
    label: '',
    alias: '',
    primary: '',
    secondary: '',
    lotStep: 0,
    lotSize: 0,
    leverage: 100,
    symbol: '',
    primaryId: '',
    secondaryId: '',
    precision: 0,
    scale: 0,
    commission: 0,
    swapLong: 0,
    swapShort: 0,
    brokerSymbol: '',
    type: { name: '', _id: '', settings: [] },
    isVisible: true
  },
  uniqueId: Date.now(),
  orderStatus: 'processing,pending',
  startAsset: '',
  brandId: '',
  isAccountsLoading: true
};

export const getTotalCredit = createAsyncThunk(
  'cfd/get/totalCredit',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/cfd-account/total-credit?userId=${userId}` });

    return response.data.totalCredit;
  }
);

export const getTotalBalance = createAsyncThunk(
  'cfd/get/totalBalance',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/cfd-account/total-balance?userId=${userId}` });

    return response.data.total;
  }
);

export const getMarketData = createAsyncThunk(
  'cfd/get/marketData',
  async () => {
    const response = await apiHelper({ method: 'get', path: '/cfd-type/settings' });

    return response.data.cfdTypes;
  }
);

export const searchByAssetName = createAsyncThunk(
  'cfd/get/assetName',
  async (name: string) => {
    const response = await apiHelper({ method: 'get', path: `/cfd-type/search?pairName=${name}` });

    return response.data.cfdTypes;
  }
);

export const getAssetInfo = createAsyncThunk(
  'cfd/get/assetInfo',
  async ({ name, accountId }: { name: string, accountId: string }) => {
    const response = await apiHelper({ method: 'get', path: `/cfd-setting/${name}?accountId=${accountId}` });

    return response.data.cfdSetting;
  }
);

export const getAssetQuote = createAsyncThunk(
  'cfd/get/assetQuote',
  async ({ symbol, userId }: { symbol: string, userId: string }) => {
    const response = await apiHelper({ method: 'get', path: `/cfd-setting/quote?symbol=${symbol}&userId=${userId}` });

    return response.data.quote;
  }
);

export const getAccounts = createAsyncThunk(
  'cfd/get/accounts',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/cfd-account/user-accounts?userId=${userId}` });

    return response.data.accounts ?? [];
  }
);

export const activateAccount = createAsyncThunk(
  'cfd/patch/account',
  async ({ accountId, userId }: { accountId: string, userId: string }) => {
    const response = await apiHelper({ method: 'patch', path: `/cfd-account/activate/${accountId}?userId=${userId}` });

    return { message: response.data.message, id: accountId };
  }
);

export const getInvestedBalance = createAsyncThunk(
  'cfd/get/instedBalance',
  async ({ accountId, userId }: { accountId: string, userId: string }) => {
    const response = await apiHelper({ method: 'get', path: `/cfd-order/invested?userId=${userId}&accountId=${accountId}` });

    return response.data.invested;
  }
);

export const getHiddenPlatformColums = createAsyncThunk(
  'cfd/get/hiddenColumns',
  async (brandId: string) => {
    const response = await apiHelper({ method: 'get', path: `/variables/hidden-platform-columns?brandId=${brandId}` });

    return response.data.columns;
  }
);

export const getStartAssetOnPlatform = createAsyncThunk(
  'cfd/get/startAsset',
  async (brandId: string) => {
    const response = await apiHelper({ method: 'get', path: `/variables/start-asset?brandId=${brandId}` });

    return response.data.asset;
  }
);

export const getBrandId = createAsyncThunk(
  'cfd/get/brandId',
  async () => {
    const hostname = window.location.hostname.startsWith('localhost') ? window.location.host : window.location.hostname;
    const response = await apiHelper({ method: 'get', path: `/brand/get-by-hostname/${hostname}` });

    return response.data.brandId;
  }
);

export const cfdSlice = createSlice({
  name: 'cfd',
  initialState,
  reducers: {
    setUniqueId (state) {
      state.uniqueId = Date.now()
    },
    setOrderStatus (state, action: PayloadAction<string>) {
      state.orderStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMarketData.fulfilled, (state, action: PayloadAction<IMarketData[]>) => {
      state.marketData = action.payload;
    });
    builder.addCase(searchByAssetName.fulfilled, (state, action: PayloadAction<IMarketData[]>) => {
      state.marketData = action.payload;
    });
    builder.addCase(getAssetInfo.fulfilled, (state, action: PayloadAction<IAssetInfo>) => {
      state.assetInfo = action.payload;
    });
    builder.addCase(getAssetQuote.fulfilled, (state, action: PayloadAction<IAssetQuote>) => {
      state.assetQuote = action.payload;
    });
    builder.addCase(getAccounts.fulfilled, (state, action: PayloadAction<IAccount[]>) => {
      state.accounts = action.payload;
      state.isAccountsLoading = false;
      state.activeAccount = action.payload.find((account) => account.isActive) ?? { balance: 0, credit: 0, symbol: '', isActive: true, _id: '', type: '', isEnabled: true }
    });
    builder.addCase(getAccounts.rejected, (state, action) => {
      state.isAccountsLoading = true;
    });
    builder.addCase(getInvestedBalance.fulfilled, (state, action: PayloadAction<number>) => {
      state.investedBalance = action.payload;
    });
    builder.addCase(getHiddenPlatformColums.fulfilled, (state, action: PayloadAction<string[]>) => {
      state.hiddenPlatformColumns = action.payload;
    });
    builder.addCase(getStartAssetOnPlatform.fulfilled, (state, action: PayloadAction<string>) => {
      state.startAsset = action.payload;
    });
    builder.addCase(getTotalBalance.fulfilled, (state, action: PayloadAction<number>) => {
      state.totalBalance = (action.payload ?? 0);
    });
    builder.addCase(getBrandId.fulfilled, (state, action: PayloadAction<string>) => {
      state.brandId = action.payload;
    });
    builder.addCase(getTotalCredit.fulfilled, (state, action: PayloadAction<number>) => {
      state.totalCredit = (action.payload ?? 0);
    });
    builder.addCase(activateAccount.fulfilled, (state, action: PayloadAction<Record<string, string>>) => {
      const accountData = state.accounts.find(({ _id }) => _id === action.payload.id) ?? { balance: 0, credit: 0, symbol: '', isActive: true, _id: '', type: '', isEnabled: true };
      state.activeAccount = { ...accountData, isActive: true };
      state.accounts = state.accounts.map((account) => {
        account.isActive = account._id === action.payload.id;

        return account;
      });
    });
  }
});

export const { setUniqueId, setOrderStatus } = cfdSlice.actions
export default cfdSlice.reducer;
