import React, { type FC, useState, useEffect, useMemo } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { t } from 'i18next';

import { DividerComponent, TradeAccountsSelectComponent, ChipsComponent, CheckboxComponent, ButtonComponent, useSnackbar, type HandleOpenInterface, TransactionModalContext } from '../../../../components';
import { type IAssetSocketData, type PayloadInterface } from '../../../../interfaces';
import { socket } from '../../../../web/socket';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';
import { getAccounts, activateAccount, getInvestedBalance, getTotalBalance, type IAssetQuote, setUniqueId } from '../../../../redux/reducers/cfd';
import { setActiveAccount } from '../../../../redux/reducers/accounts';
import { NumberInput } from './components/NumberInput';
// import { DialogContext } from '../../context/DialogContextProvider';
// import { ConfirmationModalContent, ConfirmationModalFooter } from '../../modals';
import { createOrder, getOrders } from '../../../../redux/reducers/orders';
import { RevertIcon } from '../../../../components/icons/RevertIcon';
import { toFixed } from '../../../../helpers/util';
import { getPosition, getSpread, getMargin, getEquity, getFreeMargin } from './helper';
import { handleError } from '../../../../helpers';
import { getCurrencyRates } from '../../../../redux/reducers/currencyRates';
import { setTradingMode } from '../../../../redux/reducers/tradingMode';
import Big from 'big.js';
import styles from './BuySell.module.css';
import { ModalAutoTrader } from '../../../../components/ModalAutoTrader/ModalAutoTrader';
import { ProfitCalculator } from './components/ProfitCalculator';

interface IBuySellProps {
  isOpened: boolean
  uniqueId: number
  setModalBuySell?: (el: boolean) => void
}

export const BuySell: FC<IBuySellProps> = ({ isOpened, uniqueId, setModalBuySell }) => {
  const dispatch = useAppDispatch();
  // const { showDialog, hideDialog } = useContext(DialogContext);
  const { handleOpen: handleOpenSnackbar } = useSnackbar();
  const { _id: userId } = useAppSelector((state) => state.user);
  const [isPendingChecked, setIsPendingChecked] = useState<boolean>(false);
  const [isStopLossChecked, setIsStopLossChecked] = useState<boolean>(false);
  const [isTakeProfitChecked, setIsTakeProfitChecked] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [computingSystem, setComputingSystem] = useState<string>('lots');
  const [currentMarketPrice, setCurrentMarketPrice] = useState<IAssetQuote>({ bid: 0, ask: 0 });
  const [volume, setVolume] = useState<number>(0);
  const [volumeToShow, setVolumeToShow] = useState<number | string>(0);
  const [takeProfit, setTakeProfit] = useState<number | string>(0);
  const [pendingOrderPrice, setPengingOrderPrice] = useState<number | string>(0);
  const [stopLoss, setStopLoss] = useState<number | string>(0);
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [pnl, setPnl] = useState<number>(0);
  const [openModalAutoTraider, setModalAutoTraider] = useState<boolean>(false);
  const [openProfitCalculator, setOpenProfitCalculator] = useState<boolean>(false);
  const accounts = useAppSelector((state) => state.accounts);
  const { activeAccount, assetInfo, assetQuote, investedBalance, uniqueId: renderId, orderStatus } = useAppSelector((state) => state.cfd);
  const { accountIdToPnl } = useAppSelector((state) => state.socketData);
  const {
    lotSize, leverage, lotStep, type, secondary, precision, primary, _id, commission, swapLong, swapShort, brokerSymbol, alias
  } = assetInfo;

  const spread = getSpread(currentMarketPrice?.ask, currentMarketPrice?.bid).toFixed(precision);
  const position = Object.keys(assetInfo).length > 0 ? getPosition(lotSize, volume) : 0;
  const margin = Object.keys(assetInfo).length > 0 ? getMargin(position, currentMarketPrice?.bid, leverage) : 0;
  const equity = getEquity(activeAccount.balance, pnl, activeAccount.credit);
  const freeMargin = getFreeMargin(equity, investedBalance);

  const handleAddVolume = (): void => {
    setVolumeToShow(Number(volumeToShow) + lotStep);
  };

  useEffect(() => {
    dispatch(getCurrencyRates('USD'))
  }, [])

  const handleSubVolume = (): void => {
    let value = Number(volumeToShow) - lotStep

    if (value < 0) value = 0;

    setVolumeToShow(value);
  };

  useEffect(() => {
    if (isPendingChecked) {
      setPengingOrderPrice('Market');
    }
  }, [isPendingChecked]);

  useEffect(() => {
    if (userId !== undefined && userId.length > 0) {
      Promise.all([
        dispatch(getAccounts(userId)),
        dispatch(getTotalBalance(userId))
      ]);

      setVolumeToShow(lotStep);
    }
  }, [assetInfo, userId, uniqueId]);

  useEffect(() => {
    const recieveCFDDataEvent = `recieveCFDData&${uniqueId}`;

    if (userId !== undefined && userId.length > 0 && isOpened) {
      socket.on(recieveCFDDataEvent, ({ success, market, prices }: { success: boolean, market: string, prices: IAssetSocketData[] }) => {
        if (success && market === assetInfo.type?.name) {
          const price = prices.find(({ symbol }) => symbol === assetInfo.brokerSymbol);

          if (price !== undefined) setCurrentMarketPrice(price);
        }
      })
    }

    return () => {
      setCurrentMarketPrice({ bid: 0, ask: 0 });
    };
  }, [assetInfo, userId, uniqueId, isOpened]);

  useEffect(() => {
    setSelectedAccount(activeAccount._id);
    setIsBtnLoading(false);
  }, [activeAccount]);

  useEffect(() => {
    setComputingSystem('lots');
    setVolumeToShow(lotStep);
  }, [assetInfo]);

  useEffect(() => {
    if (activeAccount !== null) {
      const accountId = activeAccount._id;

      if (accountId.length === 0) return

      setPnl(accountIdToPnl[accountId] ?? 0);
    }
  }, [accountIdToPnl, activeAccount])

  useEffect(() => {
    const accountId = activeAccount._id;

    if (userId !== undefined && userId.length > 0 && accountId.length > 0) {
      dispatch(getInvestedBalance({ userId, accountId: activeAccount._id }));
    }
  }, [isOpened, activeAccount, renderId]);

  useEffect(() => {
    let newVolume;

    if (computingSystem === 'currency') {
      /**
       * Formula here
       * newVolume = (volume / currentMarketPrice.bid) * leverage
       */

      const volumeToShowBig = new Big(Number(volumeToShow));
      const leverageBig = new Big(leverage);
      const bid = new Big(currentMarketPrice.bid);

      const volumeDividedByBid = volumeToShowBig.div(bid);
      const totalValue = volumeDividedByBid.times(leverageBig)

      const newVolumeBigNumber = totalValue.toNumber();

      newVolume = Number(newVolumeBigNumber.toFixed(8));
    } else if (computingSystem === 'units') {
      /**
       * Formula here
       * newVolume = Number(volumeToShow) / lotSize;
       */
      const volumeToShowBig = new Big(Number(volumeToShow));
      const lotSizeBig = new Big(lotSize);

      const newVolumeBigNumber = volumeToShowBig.div(lotSizeBig).toNumber();
      newVolume = Number(newVolumeBigNumber.toFixed(8));
    } else {
      newVolume = Number(Number(volumeToShow).toFixed(8));
    }

    setVolume(newVolume);
  }, [volumeToShow]);

  const handleChangeComputingSystem = (computingSystem: string): void => {
    setComputingSystem(computingSystem);

    let volumeToShow = 0;
    if (computingSystem === 'currency') {
      /**
       * Formula here
       * volumeToShow = (volume * currentMarketPrice.bid) / leverage
       */
      const volumeBig = new Big(volume);
      const leverageBig = new Big(leverage);
      const bid = new Big(currentMarketPrice.bid);

      const volumeToBid = volumeBig.times(bid);
      const totalValue = volumeToBid.div(leverageBig);

      volumeToShow = totalValue.toNumber();
    } else if (computingSystem === 'units') {
      /**
       * Formula here
       * volumeToShow = volume * lotSize
       */
      const volumeBig = new Big(volume);
      const lotSizeBig = new Big(lotSize);

      volumeToShow = volumeBig.times(lotSizeBig).toNumber();
    } else {
      volumeToShow = volume;
    }

    setVolumeToShow(volumeToShow);
  };

  useEffect(() => {
    setCurrentMarketPrice(assetQuote);
    setIsPendingChecked(false);
  }, [assetQuote]);

  const handleSelectAccount = (accountId: string): void => {
    setSelectedAccount(accountId);
    dispatch(activateAccount({ accountId, userId: userId ?? '' }));
    dispatch(setActiveAccount(accountId));
  }

  const handleCheckTakeProfit = (value: boolean): void => {
    setIsTakeProfitChecked(value);

    if (!value) {
      setTakeProfit(0);
    }
  }

  const handleCheckStopLoss = (value: boolean): void => {
    setIsStopLossChecked(value);

    if (!value) {
      setStopLoss(0);
    }
  }

  useEffect(() => {
    if (accounts.length !== 0) {
      const activeAccount = accounts.find((account) => account.isActive) ?? null;
      if (activeAccount !== null) handleSelectAccount(activeAccount?._id)
      if (activeAccount === null) {
        // select account
        const accountId: string = accounts[0]._id
        handleSelectAccount(accountId)
      }
    }
  }, [accounts])

  const options = useMemo(() => {
    return accounts.map((account) => ({
      label: `${account.type.name} ${t('labels.account')} ${account.cfdAccountCurrency.symbol}`,
      labelBalance: toFixed(account.balance, 2),
      value: account._id,
      symbol: account.cfdAccountCurrency.symbol
    }))
  }, [accounts]);

  const createOrderByType = async (tradeType: number, tradeStartPrice: number): Promise<void> => {
    setIsBtnLoading(true);

    const orderData = {
      userId,
      accountId: activeAccount._id,
      settingId: _id,
      secondary,
      primary,
      brokerSymbol,
      currentBuyRate: currentMarketPrice?.ask,
      currentSellRate: currentMarketPrice?.bid,
      userInvestedAmount: margin,
      isTPSLSet: !!(Number(takeProfit) > 0 || Number(stopLoss) > 0),
      position,
      takeProfitPrice: Number(takeProfit) > 0 ? Number(takeProfit) : undefined,
      stopLossPrice: Number(stopLoss) > 0 ? Number(stopLoss) : undefined,
      leverage,
      marketId: type?._id,
      commission,
      tradeType,
      tradeStartPrice,
      swap: tradeType === 1 ? swapLong : swapShort,
      isPending: isPendingChecked,
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      pendingOrderPrice: pendingOrderPrice || 'Market'
    };

    if (userId !== undefined && activeAccount._id.length > 0) {
      if (freeMargin < orderData.userInvestedAmount || Number(volumeToShow) <= 0) {
        const openParams: HandleOpenInterface = {
          message: 'Invalid order volume.',
          actionText: '',
          severity: 'error',
          autoClose: false
        }

        handleOpenSnackbar(openParams);
        setIsBtnLoading(false);

        return;
      }

      const response = await dispatch(createOrder(orderData));
      const payload = response.payload as PayloadInterface;

      if (payload.isError) {
        handleError(payload, handleOpenSnackbar);
        setIsBtnLoading(false);

        return;
      }

      const openParams: HandleOpenInterface = {
        message: 'Order was successfully created',
        actionText: '',
        severity: 'success',
        autoClose: true
      }

      handleOpenSnackbar(openParams);

      Promise.allSettled([
        dispatch(getAccounts(userId)),
        dispatch(getInvestedBalance({ userId, accountId: activeAccount._id })),
        dispatch(getTotalBalance(userId)),
        dispatch(getOrders({ accountId: activeAccount._id, userId, status: orderStatus }))
      ]);
    }

    dispatch(setUniqueId());
    // hideDialog();
  };

  const AccountSelectorContainer = useMemo(() => {
    return (
      <div className={styles.buySellContent__inputRow}>
        <label htmlFor="account">{t('labels.account')}</label>
        <TradeAccountsSelectComponent
            value={selectedAccount}
            onChange={({ target: { value } }) => { handleSelectAccount(value as string); }}
            options={options}
            id="account"
        />
      </div>
    )
  }, [selectedAccount, accounts])

  const backMobileBtnHandler = (): void => {
    if (setModalBuySell !== undefined) setModalBuySell(false);
  }

  const crossMobileBtnHandler = (): void => {
    if (setModalBuySell !== undefined) {
      dispatch(setTradingMode(false));
      setModalBuySell(false);
    }
  }

  const buyBtnHandler = (): void => {
    const orderPrice = toFixed(currentMarketPrice.bid, assetInfo.precision);
    const tradeType = 1;
    createOrderByType(tradeType, orderPrice)
    if (setModalBuySell !== undefined) setModalBuySell(false);
  };

  const sellBtnHandler = (): void => {
    const orderPrice = toFixed(currentMarketPrice.ask, assetInfo.precision);
    const tradeType = 0;
    createOrderByType(tradeType, orderPrice)
    if (setModalBuySell !== undefined) setModalBuySell(false);
  }
  const handleModalAutoTraiderOpen = (): void => {
    setModalAutoTraider(true);
  };

  const handleOpenProfitCalculator = (): void => {
    setOpenProfitCalculator(true)
  }

  const ProfitCalculatorModal = useMemo(() => {
    return (
      <TransactionModalContext
        open={ openProfitCalculator }
        setOpen={ setOpenProfitCalculator }
        component={
          <ProfitCalculator
            setOpen={setOpenProfitCalculator}
            currentMarketPrice={currentMarketPrice}
            currentVolume={volume}
            currentTakeProfit={takeProfit}
            currentStopLoss={stopLoss}
            primary={primary}
            secondary={secondary}
            leverage={leverage}
            lotSize={lotSize}
            freeMargin={freeMargin}
            precision={assetInfo.precision}
          />
        }
      />
    )
  }, [openProfitCalculator]);

  const tradingSystem = useMemo(() => (
    <div className={styles.buySellContent__chipsRow}>
      <ChipsComponent label={t('labels.lots')} isSelected={computingSystem === 'lots'} onClick={() => { handleChangeComputingSystem('lots'); }}/>
      <ChipsComponent label={t('labels.units')} isSelected={computingSystem === 'units'} onClick={() => { handleChangeComputingSystem('units'); }} />
      <ChipsComponent label={t('labels.currency')} isSelected={computingSystem === 'currency'} onClick={() => { handleChangeComputingSystem('currency'); }}/>
    </div>
  ), [computingSystem, volumeToShow, volume])

  return (
        <div className={styles.buySellContainer}>
            <div className={styles.buySellContainerHeader}>
              <div className={ styles.buySellContainerHeaderContent }>
                <button className={ styles.buySellContainerBackBtn } onClick={ backMobileBtnHandler }>
                  <ArrowBackIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
                </button>
                <div className={ styles.titleContainer }>
                {t('trading_page.labels.trading')}
                <span className={ styles.buySellContainerHeaderAsset }>{ alias }</span>
                <div className="buttonAutoTrader">
                  <ButtonComponent onClick={handleModalAutoTraiderOpen} variant='outlined'>
                    {t('trading_page.labels.autoTrader')}
                  </ButtonComponent>
                </div>
                </div>
              </div>
              <button className={ styles.buySellContainerBackBtn } onClick={ crossMobileBtnHandler }>
                <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
              </button>
            </div>
            <DividerComponent />
            <div className={styles.buySellContent}>
              {AccountSelectorContainer}
                <div className={styles.buySellContent__inputRow}>
                  <NumberInput
                    id="volume"
                    label={t('labels.volume')}
                    isIncBtnPresent
                    onInc={() => { handleAddVolume(); }}
                    onDec={() => { handleSubVolume(); }}
                    value={volumeToShow}
                    onChange={(value) => { setVolumeToShow(value); }}
                  />
                </div>
                <div className={styles.buySellContent_profitCalculator}>
                  <ButtonComponent variant='text' onClick={handleOpenProfitCalculator}>{t('labels.view_profit_calculator')}</ButtonComponent>
                </div>
                {tradingSystem}
                <div className={styles.buySellContent__infoTable}>
                    <div className={styles.buySellContent__infoTableRow}>
                        <span>{t('labels.contract_size')}</span>
                        <span>{lotSize}</span>
                    </div>
                    <div className={styles.buySellContent__infoTableRow}>
                        <span>{t('labels.position')}</span>
                        <span>{position}</span>
                    </div>
                    <div className={styles.buySellContent__infoTableRow}>
                        <span>{t('labels.margin')}</span>
                        <span style={{ color: margin > freeMargin ? 'var(--CFD-theme-System-Error)' : 'var(--CFD-theme-System-OnSurface)' }}>{toFixed(margin, 2)}</span>
                    </div>
                    <div className={styles.buySellContent__infoTableRow}>
                        <span>{t('labels.free_margin')}</span>
                        <span>{toFixed(freeMargin, 2)}</span>
                    </div>
                    <div className={styles.buySellContent__infoTableRow}>
                        <span>{t('labels.spread')}</span>
                        <span>{spread}</span>
                    </div>
                    <div className={styles.buySellContent__infoTableRow}>
                        <span>{t('labels.leverage')}</span>
                        <span>{`1:${leverage}`}</span>
                    </div>
                </div>
                <div className={styles.buySellContent__configurationTable}>
                    <div className={styles.buySellContent__configurationTableColumn}>
                        <div className={styles.buySellContent__configurationTableRow}>
                            <CheckboxComponent id="pending" checked={isPendingChecked} onChange={({ target: { checked } }) => { setIsPendingChecked(checked); }} />
                            <label htmlFor="pending">{t('trading_page.labels.pending')}</label>
                        </div>
                        {
                            isPendingChecked && (
                                <div className={styles.buySellContent__configurationTableColumn__subContent}>
                                    <div className={styles.buySellContent__configurationTableColumn__subContentRow}>
                                        <NumberInput
                                          id="price"
                                          label="Price"
                                          value={pendingOrderPrice}
                                          onChange={(value) => { setPengingOrderPrice(value); }}
                                        />
                                    </div>
                                    <button
                                      className={styles.revertBtn}
                                      onClick={() => { setPengingOrderPrice('Market'); }}
                                    >
                                      <RevertIcon width={18} height={18} color='var(--CFD-theme-System-OnSurfaceVariant)' />
                                      <span>{t('trading_page.labels.revert_to_market')}</span>
                                    </button>
                                    <p className={styles.supportText}>{t('trading_page.labels.pending_info')}</p>
                                </div>
                            )
                        }
                    </div>
                      <div className={styles.buySellContent__configurationTableColumn}>
                        <div className={styles.buySellContent__configurationTableRow}>
                            <CheckboxComponent id="takeprofit" checked={isTakeProfitChecked} onChange={({ target: { checked } }) => { handleCheckTakeProfit(checked); }}/>
                            <label htmlFor="takeprofit">{t('labels.take_profit')}</label>
                        </div>
                        {
                            isTakeProfitChecked && (
                                <div className={styles.buySellContent__configurationTableColumn__subContent}>
                                    <div className={styles.buySellContent__configurationTableColumn__subContentRow}>
                                        <NumberInput
                                        id="takeprofitField"
                                        label={t('labels.take_profit')}
                                        value={takeProfit}
                                        onChange={(value) => { setTakeProfit(value); }}
                                        />
                                    </div>
                                    {/* <p className={styles.supportText} style={{ paddingTop: '6px' }}>Loss: $75.00 (0.44%)</p>
                                    <p className={styles.supportText} style={{ paddingTop: '6px' }}>Rate: 32,650.00</p> */}
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.buySellContent__configurationTableColumn}>
                        <div className={styles.buySellContent__configurationTableRow}>
                            <CheckboxComponent id="stoploss" checked={isStopLossChecked} onChange={({ target: { checked } }) => { handleCheckStopLoss(checked); }}/>
                            <label htmlFor="stoploss">{t('labels.stop_loss')}</label>
                        </div>
                        {
                            isStopLossChecked && (
                                <div className={styles.buySellContent__configurationTableColumn__subContent}>
                                    <div className={styles.buySellContent__configurationTableColumn__subContentRow}>
                                        <NumberInput
                                        id="stoplossField"
                                        label={t('labels.stop_loss')}
                                        value={stopLoss}
                                        onChange={(value) => { setStopLoss(value); }}
                                        />
                                    </div>
                                    {/* <p className={styles.supportText} style={{ paddingTop: '6px' }}>Loss: $75.00 (0.44%)</p>
                                    <p className={styles.supportText} style={{ paddingTop: '6px' }}>Rate: 32,650.00</p> */}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={styles.buySellContainer__mainBtnContainer}>
              {
                (!isOpened || currentMarketPrice.bid <= 0)
                  ? (
                      <ButtonComponent variant='text' disabled>{t('labels.closed')}</ButtonComponent>
                    )
                  : (
                      <ButtonComponent
                        btnstyle='buy'
                        loading={ isBtnLoading }
                        //
                        // onClick={() => {
                        //   showDialog({
                        //     headerContent: <div className={styles.dialogHeader}>{t('trading_page.labels.buy')}</div>,
                        //     bodyContent: <ConfirmationModalContent orderPrice={toFixed(currentMarketPrice.bid, assetInfo.precision)} position={position} orderValue={margin} />,
                        //     footerContent: <ConfirmationModalFooter
                        //       orderPrice={toFixed(currentMarketPrice.bid, assetInfo.precision)}
                        //       tradeType={1}
                        //       onClick={(tradeType, orderPrice) => { createOrderByType(tradeType, orderPrice); }}
                        //       isLoading={isBtnLoading}
                        //     />
                        //   });
                        // }}
                        onClick={ buyBtnHandler }
                      >
                        <span className='btn-text'>{t('trading_page.labels.buy')}</span>
                        <span className='btn-price'>{toFixed(currentMarketPrice.bid, assetInfo.precision)}</span>
                      </ButtonComponent>
                    )
              }
              {
                (!isOpened || currentMarketPrice.ask <= 0)
                  ? (
                      <ButtonComponent variant='text' disabled>{t('labels.closed')}</ButtonComponent>
                    )
                  : (
                      <ButtonComponent
                        btnstyle='sell'
                        loading={ isBtnLoading }
                        // onClick={() => {
                        //   showDialog({
                        //     headerContent: <div className={styles.dialogHeader}>{t('trading_page.labels.sell')}</div>,
                        //     bodyContent: <ConfirmationModalContent orderPrice={toFixed(currentMarketPrice.ask, assetInfo.precision)} position={position} orderValue={margin} />,
                        //     footerContent: <ConfirmationModalFooter
                        //       orderPrice={toFixed(currentMarketPrice.ask, assetInfo.precision)}
                        //       tradeType={0}
                        //       onClick={(tradeType, orderPrice) => { createOrderByType(tradeType, orderPrice); }}
                        //       isLoading={isBtnLoading}
                        //     />
                        //   });
                        // }}
                        onClick={ sellBtnHandler }
                      >
                        <span className='btn-text'>{t('trading_page.labels.sell')}</span>
                        <span className='btn-price'>{toFixed(currentMarketPrice.ask, assetInfo.precision)}</span>
                      </ButtonComponent>
                    )
              }
            </div>
              {
                openModalAutoTraider && <ModalAutoTrader open={openModalAutoTraider} setOpen={setModalAutoTraider} />
              }
              {ProfitCalculatorModal}
            </div>
  )
}
