import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type WithdrawalType } from './withdrawalTypes';
import { apiHelper } from '../api/apiHelper';

export interface IUserWithdrawalTypes {
  _id: string
  userId: string
  withdrawalTypesId: string
  type: string
  isEnabled: boolean
  createdAt: Date | string
  updatedAt: Date | string
  withdrawalTypes: WithdrawalType[]
}

const initialState: IUserWithdrawalTypes[] = [];

export const getUserWithdrawalTypes = createAsyncThunk(
  'userWithdrawalTypes/get',
  async (userId: string) => {
    const response = await apiHelper({ method: 'get', path: `/user-withdrawal-types/user/${userId}` });

    return response.data.allUserWithdrawalTypes;
  }
)

export const userWithdrawalTypesSlice = createSlice({
  name: 'userWithdrawalTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserWithdrawalTypes.fulfilled, (state, action: PayloadAction<IUserWithdrawalTypes[]>) => {
      return action.payload;
    });
  }
});

export default userWithdrawalTypesSlice.reducer;
