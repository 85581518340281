import React, { type FC } from 'react';
import { t } from 'i18next';

import {
  SectionSuccessModal,
  ButtonComponent
} from '../../../../components';

import style from './SuccessDepositFormComponent.module.css';

interface InterfaceSuccessDepositFormComponent {
  title: string
  onClick: () => void
  account: string
  amount: string
}

export const SuccessDepositFormComponent: FC<InterfaceSuccessDepositFormComponent> = ({ title, onClick, account, amount }) => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const datePart = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

  const timePart = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  }).format(now)

  const formattedDateTime = `${datePart} ${timePart}`;

  return (
   <>
   <div className={ style.wrapper }>
    <div className={ style.successSection }>
      <SectionSuccessModal title={ title }/>
      <div className={ style.contentContainer }>
        <div className={ style.content }>
          <p className={ style.contentTitle }>{t('labels.deposit_to')}</p>
          <p className={ style.contentText }>{ account }</p>
        </div>
        <div className={ style.content }>
          <p className={ style.contentTitle }>{t('labels.amount')}</p>
          <p className={ style.contentText }>{ amount }</p>
        </div>
        <div className={ style.contentDate }>
          <p className={ style.contentTitle }>{t('labels.date')}</p>
          <p className={ style.contentText }>{ formattedDateTime }</p>
        </div>
      </div>
    </div>
   </div>
    <footer className={ style.footer }>
      <div className={ style.btnContainer }>
        <ButtonComponent onClick={ onClick } variant='outlined'>
          <span className={ style.btnText }>
            {t('labels.view_account')}
          </span>
        </ButtonComponent>
      </div>
    </footer>
   </>
  )
}
